import Chart from "react-apexcharts";

const FireHeaderChart = ({ target, type }) => {
  return (
    <Chart
      options={{
        grid: {
          padding: {
            top: -10,
            bottom: -15,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              //   margin: 5,
              size: "48%",
            },
            dataLabels: {
              name: {
                offsetY: -5,
                show: true,
                color: type === "other" ? "#e4e4e4" : "#888",
                fontSize: "8px",
                fontWeight: 400,
              },
              value: {
                offsetY: -12,
                // color: "#111",
                color: type === "other" ? "grey" : "#111",
                fontSize: type === "main" ? "10px" : "9px",
                fontWeight: 800,
                show: true,
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        //   labels: [item.symbol.toUpperCase()],
        labels: [""],
        // colors: ["hsl(198, 100%, 28%)"],
        colors: [type === "other" ? "black" : target > 75 ? "hsl(93, 67%, 38%)" : "hsl(48, 95%, 48%)"],
      }}
      series={[target]}
      type="radialBar"
      //   width="100"
      height={type === "main" ? "110" : "100"}
    />
  );
};

export default FireHeaderChart;
