import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { pushRecord, getChildData, editChildData } from "../../functions/funcFirebase";
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonCol, IonContent, IonIcon, useIonAlert, IonItem, IonLabel, IonPage, IonRow } from "@ionic/react";
import { trashOutline, createOutline } from "ionicons/icons";

const StockGroupManage = () => {
  // SET THE ITEM NAME FOR FIREBASE OPS
  const itemName = "stockGroup";

  const userID = useSelector((state) => state.user.uid);
  const [itemsData, setItemsData] = useState([]);
  const [formType, setFormType] = useState("");
  const [form, setForm] = useState({});
  const [activeID, setActiveID] = useState("");
  const [editID, setEditID] = useState("");
  const [present] = useIonAlert();

  const getItems = async () => {
    const resData = await getChildData(userID, itemName);
    setItemsData(resData && resData.data && Object.entries(resData.data));
  };

  const populateItem = async (id) => {
    setForm({});
    setActiveID(id);
    setEditID(id);
    setFormType("edit");
    const resData = await getChildData(userID, `/${itemName}/${id}`);
    setForm(resData.data);
  };

  const deleteItem = async (id) => {
    editChildData(userID, `${itemName}/${id}`, null);
    getItems();
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/stocks" />
          </IonButtons>
          <IonTitle>Stock Groups</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            <div>
              <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
                <div onClick={() => setFormType("add")} className="general-button" style={{ width: "200px" }}>
                  Add Group
                </div>
              </div>
              <p />

              {/* The Add Form (Look below) */}
              {formType === "add" && <AddForm userID={userID} itemName={itemName} formType={formType} setFormType={setFormType} getItems={getItems} />}

              {/* The Edit Form (Look below) */}
              {formType === "edit" && (
                <EditForm
                  userID={userID}
                  itemName={itemName}
                  formType={formType}
                  setFormType={setFormType}
                  getItems={getItems}
                  activeID={activeID}
                  editID={editID}
                  form={form}
                  setForm={setForm}
                />
              )}

              {itemsData && itemsData.length >= 0 ? (
                itemsData.map((item) => {
                  let theKey = item[0];
                  let theData = item[1];
                  return (
                    <IonItem key={theKey}>
                      <IonLabel>
                        <strong>{theData.name}</strong>

                        <p>{theData.symbols && theData.symbols.toUpperCase()}</p>
                      </IonLabel>

                      <IonIcon
                        icon={createOutline}
                        onClick={() => populateItem(theKey)}
                        style={{ paddingRight: "4px", paddingLeft: "16px", color: "darkblue", fontSize: "18px" }}
                      />

                      <IonIcon
                        icon={trashOutline}
                        onClick={() =>
                          present({
                            cssClass: "my-css",
                            header: "Delete",
                            message: "Are you sure you want to delete this item?",
                            buttons: ["Cancel", { text: "Delete", handler: (d) => deleteItem(theKey) }],
                          })
                        }
                        style={{ paddingLeft: "6px", color: "darkred", fontSize: "18px" }}
                      />
                    </IonItem>
                  );
                })
              ) : (
                // ####################### NO Stocks ADDED YET #######################
                <div style={{ textAlign: "center", fontSize: "16px", fontWeight: 500, color: "darkgray" }}>No Stock Groups Added Yet</div>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default StockGroupManage;

// ########################################################
// ####################### ADD FORM #######################
const AddForm = ({ userID, itemName, setFormType, formType, getItems }) => {
  const [form, setForm] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const addItem = () => {
    pushRecord(userID, itemName, form);
    setForm({});
    setFormType("");
    getItems();
  };

  return (
    formType === "add" && (
      <div className="stock-account-form-edit">
        <table className="stock-account-table">
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>Name:</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="name" value={form["name"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Symbols</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="symbols" value={form["symbols"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Options:</td>
              <td style={{ width: "60%" }}>
                {form.fav ? (
                  <span className="stock-account-tag" onClick={() => setForm({ ...form, fav: false })}>
                    Favorite
                  </span>
                ) : (
                  <span className="stock-account-tag-off" onClick={() => setForm({ ...form, fav: true })}>
                    Favorite
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <p />

        <div className="bottons-divider">
          <div onClick={() => addItem()} className="general-button-small">
            Add
          </div>
          <div onClick={() => setFormType("")} className="general-button-small-cancel">
            Cancel
          </div>
        </div>
      </div>
    )
  );
};

// #########################################################
// ####################### EDIT FORM #######################
const EditForm = ({ userID, itemName, setFormType, formType, getItems, editID, activeID, form, setForm }) => {
  //   const [form, setForm] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const editItem = async () => {
    // setFormType("edit");
    editChildData(userID, `${itemName}/${activeID}`, form);
    setFormType("");
    setForm({});
    getItems();
  };

  return (
    formType === "edit" &&
    editID === activeID && (
      <div className="stock-account-form-edit">
        <table className="stock-account-table">
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>Name:</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="name" value={form["name"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Symbols</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="symbols" value={form["symbols"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Options:</td>
              <td style={{ width: "60%" }}>
                {form.fav ? (
                  <span className="stock-account-tag" onClick={() => setForm({ ...form, fav: false })}>
                    Favorite
                  </span>
                ) : (
                  <span className="stock-account-tag-off" onClick={() => setForm({ ...form, fav: true })}>
                    Favorite
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <p />
        <div className="bottons-divider">
          <div onClick={() => editItem()} className="general-button-small">
            Save
          </div>
          <div onClick={() => setFormType("")} className="general-button-small-cancel">
            Cancel
          </div>
        </div>
      </div>
    )
  );
};
