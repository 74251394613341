import { IonIcon } from "@ionic/react";
import { cloudDownload, pulse } from "ionicons/icons";
import { fN } from "../../functions/baseFunctions";

const StocksStats: React.FC<any> = ({ privacyMode, stocksNetLiq, stocksROI, stockProfits, stocksInvestedCash, stockPNL }): JSX.Element => {
  return (
    <div className="icons-top-wrapper">
      <div className="num-coins">
        <IonIcon icon={cloudDownload} size="small" style={{ paddingLeft: "10px", color: "grey" }} /> Invested
        <br />
        <div className="item-value">${privacyMode ? "****" : fN(stocksInvestedCash)}</div>
      </div>
      <div className="num-coins">
        <span style={{ fontSize: "10px", letterSpacing: "1px" }}> ROI / NetLiq</span>
        <br />
        <div style={{ fontSize: "18px" }}>{privacyMode ? "**" : stocksROI}%</div>
        {<span style={{ fontSize: "20px", fontWeight: 500, lineHeight: "0.5px", letterSpacing: "-1px" }}>${privacyMode ? "******" : fN(Math.floor(stocksNetLiq))}</span>}
      </div>
      <div className="num-last">
        <IonIcon icon={pulse} size="small" style={{ paddingLeft: "10px", color: "grey" }} /> Profits
        <br />
        <span className="item-value">${privacyMode ? "****" : fN(Math.floor(stockProfits))}</span>
        <br />
        <span style={{ fontSize: "12px" }}>
          Daily:{" "}
          <span style={stockPNL >= 0 ? { color: "green" } : { color: "red" }}>
            <b>${privacyMode ? "****" : fN(Math.floor(stockPNL))}</b>
          </span>
        </span>
      </div>
    </div>
  );
};

export default StocksStats;
