import React from "react";

const CryptoWatchList: React.FC<any> = ({ cryptoWatchList }): JSX.Element => {
  return (
    <>
      {cryptoWatchList.length > 0 &&
        cryptoWatchList
          .sort((a: any, b: any) => (a.symbol > b.symbol ? 1 : -1))
          .map((item: any, index: any) => (
            <div className="crypto-top-coin-item" key={index}>
              <span style={{ fontWeight: 600, marginRight: "2px", fontSize: "16px" }}>{index + 1} </span>
              <b>{item.symbol}</b> | {item.price}
              {/* <span className="top-crypto-markcap">{item.DISPLAY.USD.MKTCAP}</span> */}
            </div>
          ))}
    </>
  );
};

export default CryptoWatchList;
