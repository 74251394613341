import { Fragment, useEffect, useState } from "react";
import StockSummaryChart from "./StockSummaryChart";

const StockMarketSummary = ({ marketSummary, token, remoteConfig }) => {
  const [showChart, setShowChart] = useState(false);
  const [chartSymbol, setChartSymbol] = useState();
  const [chartName, setChartName] = useState();
  const [lastPrice, setLastPrice] = useState();

  const handleChart = (sym, name, last) => {
    setShowChart(true);
    setChartSymbol(sym);
    setChartName(name);
    setLastPrice(last);
  };

  // useEffect(() => {
  //   console.log("marketSummary: === >", marketSummary);
  // }, [marketSummary]);

  return (
    <Fragment>
      <div className="stock-market-summary-wrapper">
        <div style={{ width: "max-content", marginTop: "4px", marginBottom: "4px" }}>
          {marketSummary &&
            marketSummary.length > 0 &&
            marketSummary.map((item, index) => (
              <div
                className="stock-indices-item-upper"
                key={index}
                onClick={() => handleChart(item.symbol, item.shortName ? item.shortName : item.symbol, item.regularMarketPrice.raw)}
              >
                <div>
                  <strong>{item.shortName ? item.shortName.slice(0, 10) : item.symbol}</strong>
                  <br />
                  <span
                    style={
                      item.regularMarketChange.raw > 0
                        ? { fontSize: "13px", color: "hsl(93, 67%, 38%)", letterSpacing: "1.5px", fontWeight: 700 }
                        : { fontSize: "13px", color: "hsl(9, 100%, 43%)", letterSpacing: "1.5px", fontWeight: 700 }
                    }
                  >
                    {item.regularMarketChangePercent.fmt}
                  </span>
                </div>
                <div
                  className="stock-indices-item-bottom"
                  style={
                    item.regularMarketChange.raw > 0
                      ? { fontSize: "9px", color: "white", backgroundColor: "hsl(93, 67%, 38%)" }
                      : { fontSize: "9px", color: "white", backgroundColor: "hsl(9, 100%, 43%)" }
                  }
                >
                  {item.regularMarketPrice.fmt} | {item.regularMarketChange.fmt}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div>
        {showChart && <StockSummaryChart symbol={chartSymbol} chartName={chartName} lastPrice={lastPrice} token={token} remoteConfig={remoteConfig} setShowChart={setShowChart} />}
      </div>
    </Fragment>
  );
};

export default StockMarketSummary;
