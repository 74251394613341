import axios from "axios";

const removeDuplicates = (array, prop) => {
  const lookup = array.reduce((acc, item) => {
    acc[item[prop]] = item;
    return acc;
  }, {});
  return Object.values(lookup);
};

const getCryptoData = async (userID, remoteConfig, symbols) => {
  const formattedSymbols = symbols.map((symbol) => `${symbol.toUpperCase()}-USD`);
  if (formattedSymbols.length === 0) return null;

  try {
    const { data } = await axios.get(`${remoteConfig.stockURL}/stock`, {
      params: { sym: formattedSymbols.join(","), appToken: userID },
    });
    return data.stocks;
  } catch (error) {
    console.error("Error getting Live Stock: ", error.message);
    return null;
  }
};

export const getCryptoInfo = async (userID, remoteConfig, rawPositions) => {
  const cryptoInfoArray = [];
  const symbols = [];
  const positions = [];
  let cryptoInvested = 0;
  const cryptoInfoStats = {
    cryptoTotal: 0,
    cryptoInvested: 0,
    cryptoProfits: 0,
    cryptoROI: 0,
    numberOfStocks: 0,
    cryptoDailyPNL: 0,
    numberOfBrokers: 0,
    numberOfCoins: 0,
  };

  if (rawPositions) {
    Object.entries(rawPositions).forEach(([key, data]) => {
      symbols.push(data.symbol);
      positions.push({ data, key });
      cryptoInvested += Number(data.invested);
    });
  }

  cryptoInfoStats.numberOfBrokers = removeDuplicates(
    positions.map((p) => p.data),
    "broker"
  ).length;
  cryptoInfoStats.numberOfCoins = removeDuplicates(
    positions.map((p) => p.data),
    "symbol"
  ).length;

  const cryptoData = await getCryptoData(userID, remoteConfig, [...new Set(symbols)]);
  if (!cryptoData) return { crypto: cryptoInfoArray, stats: cryptoInfoStats };

  positions.forEach(({ key, data }) => {
    const matchedData = cryptoData.find((item) => item.symbol.toUpperCase() === `${data.symbol.toUpperCase()}-USD`);
    if (matchedData) {
      const value = Math.floor(matchedData.regularMarketPrice * data.coins);
      const profits = value - data.invested;
      cryptoInfoArray.push({
        fbID: key,
        order: data.order || 1,
        shortName: matchedData.shortName,
        symbol: data.symbol,
        broker: data.broker,
        coins: data.coins,
        staking: data.staking,
        stakingRate: data.stakingRate,
        invested: data.invested,
        last: matchedData.regularMarketPrice,
        change: matchedData.regularMarketChange,
        changePercent: matchedData.regularMarketChangePercent,
        fiftyTwoWeekRange: matchedData.fiftyTwoWeekRange,
        value,
        profits,
        fav: data.fav,
      });
      cryptoInfoStats.cryptoTotal += matchedData.regularMarketPrice * data.coins;
      cryptoInfoStats.cryptoProfits += profits;
      cryptoInfoStats.cryptoInvested += Number(data.invested);
      cryptoInfoStats.cryptoDailyPNL += matchedData.regularMarketChange * data.coins;
    }
  });

  if (cryptoInfoStats.cryptoInvested > 0) {
    cryptoInfoStats.cryptoROI = ((cryptoInfoStats.cryptoProfits / cryptoInfoStats.cryptoInvested) * 100).toFixed(1);
  }

  return { crypto: cryptoInfoArray, stats: cryptoInfoStats };
};

export const getTopCryptoData = async (userID, remoteConfig) => {
  try {
    const { data } = await axios.get(`${remoteConfig.stockURL}/crypto`, {
      params: { appToken: userID, topCoins: true },
    });
    return data.topCoins;
  } catch (error) {
    console.error("Error getting Top Crypto Data: ", error.message);
    return null;
  }
};
