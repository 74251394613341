import { IonSpinner, IonIcon } from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { refreshCircle } from "ionicons/icons";
import { getBLSData } from "../../functions/stockFunctions";

const BLSChart = ({ token }) => {
  const [dates, setDates] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartName, setChartName] = useState("cpi");
  const [chartRange, setChartRange] = useState(-12);

  const processData = async (chartName, sizer) => {
    const prerepedData = await getBLSData(token, chartRange);

    setDates(prerepedData[`dates${chartName}`]);
    setChartData([
      {
        name: chartName,
        data: prerepedData[chartName],
      },
    ]);
  };

  useEffect(() => {
    processData(chartName, chartRange);
  }, [chartName, chartRange]);

  const refresh = () => {
    setDates([]);
    setChartData([]);
    processData(chartName);
  };

  let options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: "bold",
      },
      background: {
        enabled: true,
        opacity: 0.8,
      },
    },
    colors: ["#77B6EA", "#545454"],

    stroke: {
      curve: "smooth",
      colors: "black",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
      padding: {
        top: -2,
        bottom: -8,
        // left: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => Number(value).toFixed(1) + "%",
        style: {
          fontSize: "10px",
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      categories: dates,
      tickAmount: 12,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      floating: true,
    },
  };

  return (
    <Fragment>
      <br />
      <div style={{ justifyContent: "center", display: "flex" }}>
        <span className={chartName === "cpi" ? "nav-plotter-item-active" : "nav-plotter-item"} onClick={() => setChartName("cpi")}>
          CPI
        </span>{" "}
        <span className={chartName === "ppi" ? "nav-plotter-item-active" : "nav-plotter-item"} onClick={() => setChartName("ppi")}>
          PPI
        </span>
        <span className={chartName === "jolts" ? "nav-plotter-item-active" : "nav-plotter-item"} onClick={() => setChartName("jolts")}>
          JOLTS
        </span>
        <span onClick={() => refresh()} style={{ fontSize: "28px", marginLeft: "14px", marginBottom: "-8px", padding: "0px" }}>
          <IonIcon icon={refreshCircle}></IonIcon>
        </span>
      </div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <span className={chartRange === -6 ? "stock-bls-chart-range-active" : "stock-bls-chart-range"} onClick={() => setChartRange(-6)}>
          6M
        </span>
        <span className={chartRange === -12 ? "stock-bls-chart-range-active" : "stock-bls-chart-range"} onClick={() => setChartRange(-12)}>
          12M
        </span>
        <span className={chartRange === -24 ? "stock-bls-chart-range-active" : "stock-bls-chart-range"} onClick={() => setChartRange(-24)}>
          24M
        </span>
      </div>
      <div style={{ justifyContent: "center", margin: "0px" }}>
        {chartData.length > 0 ? (
          <div id="chart">
            <Chart options={options} series={chartData} type="line" height={300} />
          </div>
        ) : (
          <div style={{ justifyContent: "center", display: "flex", margin: "10px" }}>
            <IonSpinner />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default BLSChart;
