import { useEffect, useState } from "react";

import FireHeaderChart from "./FireHeaderChart";
import { fN } from "../../functions/baseFunctions";

const WithPercent = ({ fireKey, percent, fireTotalAmount, switchRulePercent, homeStockData, homeCryptoData, endingCash, includeStockCashAsInvestment, assets }) => {
  const percentArray = [2, 3, 4, 5, 6];
  const [totalInvestment, setTotalInvestment] = useState(0);

  useEffect(() => {
    if (includeStockCashAsInvestment && homeStockData.stats && homeCryptoData.stats) {
      setTotalInvestment(Number(homeStockData.stats.total) + Number(homeCryptoData.stats.cryptoTotal) + Number(assets));
    } else if (!includeStockCashAsInvestment && homeStockData && homeCryptoData) {
      setTotalInvestment(Number(homeStockData.stats.total - endingCash) + Number(homeCryptoData.stats.cryptoTotal) + Number(assets));
    }
  }, [homeStockData, endingCash, includeStockCashAsInvestment]);

  return (
    <div className="fire-withPercent-wrapper">
      {percentArray.map((per, ind) => {
        return (
          <div key={ind}>
            <div className={percent === per ? "fire-withPercent-item-active" : ""} onClick={() => switchRulePercent(fireKey, per)}>
              {percent === per && <span style={{ fontSize: "8px", color: "black", fontWeight: 400 }}>SWR / Amount</span>}
              <br />
              {per}%
              <br />
              <span style={{ color: "black" }}>${fN(Math.floor(fireTotalAmount / (per / 100)))}</span>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <FireHeaderChart target={Number(((totalInvestment / Math.floor(fireTotalAmount / (per / 100))) * 100).toFixed(2))} type={percent === per ? "main" : "other"} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WithPercent;
