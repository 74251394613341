import React, { Fragment } from "react";
import { fNK } from "../../functions/baseFunctions";

const CryptoFavHome: React.FC<any> = ({ cryptoInfo }): JSX.Element => {
  return (
    <Fragment>
      <div className="crypto-fav-home">
        {cryptoInfo &&
          cryptoInfo.length > 0 &&
          cryptoInfo
            .filter((item: any) => item.fav === true)
            .sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
            .map((item: any, index: number) => (
              <span
                key={index}
                style={{
                  fontSize: "11px",
                  color: "white",
                  margin: "3px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderWidth: "1px",
                  borderRadius: "4px",
                  backgroundColor: item.change >= 0 ? "hsl(93, 85%, 32%)" : "hsl(9, 100%, 43%)",
                }}
              >
                <b>{item.symbol.toUpperCase()}</b>: ${item.last > 1000 ? fNK(Math.floor(item.last)) : item.last.toFixed(2)}
              </span>
            ))}
      </div>
      <p />
    </Fragment>
  );
};

export default CryptoFavHome;
