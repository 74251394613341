import { fN } from "../../functions/baseFunctions";
import StockGainersLosersTable from "./StockGainersLosersTable";

const StockGainersLosers = ({ data, count, privacyMode, filteredStock, setFilteredStock, setStockPanelOpen, setActiveStock, setRefresh }) => {
  return (
    <>
      <div className="stock-gainerslosers-wrapper">
        <StockGainersLosersTable
          data={data}
          privacyMode={privacyMode}
          count={8}
          operator={"greaterThan"}
          title={"Top Performers"}
          wrapperClass={"stock-gainerslosers-item-left"}
          tableClass={"stock-gainers-title"}
          filteredStock={filteredStock}
          setFilteredStock={setFilteredStock}
          setStockPanelOpen={setStockPanelOpen}
          setActiveStock={setActiveStock}
          setRefresh={setRefresh}
        />
        <StockGainersLosersTable
          data={data}
          privacyMode={privacyMode}
          count={8}
          operator={"lessThan"}
          title={"Bottom Performers"}
          wrapperClass={"stock-gainerslosers-item-right"}
          tableClass={"stock-losers-title"}
          filteredStock={filteredStock}
          setFilteredStock={setFilteredStock}
          setStockPanelOpen={setStockPanelOpen}
          setActiveStock={setActiveStock}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default StockGainersLosers;
