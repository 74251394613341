import { Fragment, useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { fN, trunc } from "../../functions/baseFunctions";

const FireStatus = ({ privacyMode, stocksNetLiq, cryptoInvestments, data, endingCash, includeStockCashAsInvestment, assets }) => {
  const [wrapper, setWrapper] = useState(3);
  const [fireData, setFireData] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0);

  useEffect(() => {
    let fireDataArray = [];
    data &&
      Object.entries(data).forEach((item) => {
        let theData = item[1];
        if (theData.fav) {
          fireDataArray.push({
            fireName: theData.name,
            mainPlan: theData.default,
            fireNumber: Math.floor(theData.fireNumber / (theData.rulePercent / 100)),
            fireTarget: Number(((totalInvestment / Math.floor(theData.fireNumber / (theData.rulePercent / 100))) * 100).toFixed(2)),
          });
        }
      });
    setWrapper(fireDataArray.length <= 3 ? fireDataArray.length : 3);
    setFireData(fireDataArray);
  }, [totalInvestment]);

  useEffect(() => {
    // Calc the Chart Numbers
    setTotalInvestment(includeStockCashAsInvestment ? stocksNetLiq + cryptoInvestments + assets : stocksNetLiq - endingCash + cryptoInvestments + assets);
  }, [stocksNetLiq, cryptoInvestments, endingCash, includeStockCashAsInvestment, assets]);

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center", fontWeight: 400, color: "grey", fontSize: "11px", letterSpacing: "1px" }}>Current Investments</div>
      <div style={{ justifyContent: "center", display: "flex", fontSize: "20px", fontWeight: 500, letterSpacing: "0.5px" }}>
        ${totalInvestment && fN(Math.floor(totalInvestment))}
      </div>

      <div className={`home-fire-charts-wrapper-${wrapper}`}>
        {fireData.length > 0 &&
          fireData.map((item, index) => (
            <div className="main-fire-chart" key={index}>
              <div className={item.mainPlan ? "fire-plan-type-main" : "fire-plan-type-alt"}>{item.mainPlan ? "Main Plan" : "Alternative"}</div>
              <div style={{ margin: "0px", padding: "0px", justifyContent: "center", display: "flex" }}>
                <Chart
                  options={{
                    grid: {
                      padding: {
                        top: -14,
                        bottom: -20,
                      },
                    },
                    plotOptions: {
                      radialBar: {
                        hollow: {
                          margin: 10,
                          size: "60%",
                        },
                        dataLabels: {
                          showOn: "always",
                          name: {
                            offsetY: -11,
                            show: true,
                            color: "#888",
                            fontSize: "11px",
                          },
                          value: {
                            offsetY: -1,
                            color: "#111",
                            fontSize: "20px",
                            show: true,
                          },
                        },
                      },
                    },
                    stroke: {
                      lineCap: "round",
                    },
                    labels: ["Target"],
                    colors: [item.fireTarget >= 75 ? "hsl(93, 67%, 38%)" : "hsl(48, 95%, 48%)"],
                  }}
                  series={privacyMode ? [0] : [item.fireTarget && item.fireTarget]}
                  type="radialBar"
                  width="150"
                  height="170"
                />
              </div>
              <div style={{ justifyContent: "center", display: "flex", fontWeight: 500, color: "black", fontSize: "12px" }}>{trunc(item.fireName, 20)}</div>
              <div style={{ justifyContent: "center", display: "flex" }}>{fN(Math.floor(item.fireNumber))}</div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};

export default FireStatus;
