import { IonItem, IonList, IonIcon, useIonAlert, IonLabel } from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import { pushRecord, getChildData, editChildData } from "../../functions/funcFirebase";
import { trashOutline, qrCodeOutline, createOutline } from "ionicons/icons";

const AddAccount = ({ userID }) => {
  const [accountsData, setAccountsData] = useState([]);
  const [activeID, setActiveID] = useState("");
  const [form, setForm] = useState({});
  const [formType, setFormType] = useState("");
  const [present] = useIonAlert();

  const getAcc = async () => {
    const resData = await getChildData(userID, "accounts");
    setAccountsData(resData && resData.data && Object.entries(resData.data));
  };

  const addAcc = () => {
    const path = "accounts";
    pushRecord(userID, path, form);
    setForm({});
    setFormType("");
  };

  const editItem = async (id) => {
    setForm({});
    setActiveID(id);
    setFormType("edit");
    const resData = await getChildData(userID, `/accounts/${id}`);
    setForm(resData.data);
  };

  const editAcc = async () => {
    setFormType("edit");
    editChildData(userID, `accounts/${activeID}`, form);
    setFormType("");
    setForm({});
  };

  const deleteAcc = async (id) => {
    editChildData(userID, `accounts/${id}`, null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    getAcc();
  });

  return (
    <Fragment>
      <div>
        <div className="bottons-divider">
          <div onClick={() => setFormType("add")} className="general-button">
            Add Account
          </div>
          <div onClick={() => getAcc()} className="general-button">
            List Accounts
          </div>
        </div>
        <div>
          <IonList>
            {accountsData &&
              accountsData.length > 0 &&
              accountsData.map((item) => {
                let theKey = item[0];
                let theData = item[1];
                return (
                  <IonItem key={theKey}>
                    <IonLabel>
                      <IonIcon icon={qrCodeOutline} style={{ paddingRight: "6px", color: "black", fontSize: "18px" }} />
                      {theData.name}
                    </IonLabel>

                    <IonIcon icon={createOutline} onClick={() => editItem(theKey)} style={{ paddingRight: "4px", paddingLeft: "16px", color: "darkblue", fontSize: "18px" }} />
                    <IonIcon
                      icon={trashOutline}
                      onClick={() =>
                        present({
                          cssClass: "my-css",
                          header: "Delete",
                          message: "Are you sure you want to delete this item?",
                          buttons: ["Cancel", { text: "Delete", handler: (d) => deleteAcc(theKey) }],
                        })
                      }
                      style={{ paddingLeft: "4px", color: "darkred", fontSize: "18px" }}
                    />
                  </IonItem>
                );
              })}
          </IonList>
        </div>
        <p />
        <p />
      </div>

      {/* // ####################### ADD/EDIT FORM ####################### */}
      <div>
        {(formType === "add" || formType === "edit") && (
          <div className="stock-account-form-edit">
            <table className="stock-account-table">
              <tbody>
                <tr>
                  <td style={{ width: "40%" }}>Type:</td>
                  <td style={{ width: "60%" }}>
                    <select className="stock-account-input" onChange={(e) => setForm({ ...form, type: e.target.value })}>
                      <option className="stock-account-input" value="Other" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                        {form.type}
                      </option>
                      <option className="stock-account-input" value="IBKR" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                        Interavtive Brokers
                      </option>
                      <option className="stock-account-input" value="Manual" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                        Manual
                      </option>
                      <option className="stock-account-input" value="Simulation" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                        Simulation (Paper)
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%" }}>Name:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="name" value={form["name"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                {form.type === "IBKR" && (
                  <>
                    <tr>
                      <td style={{ width: "40%" }}>Query ID:</td>
                      <td style={{ width: "60%" }}>
                        <input type="text" className="stock-account-input" name="queryID" value={form["queryID"] || ""} onChange={handleChange} />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "40%" }}>Token:</td>
                      <td style={{ width: "60%" }}>
                        <input type="text" className="stock-account-input" name="token" value={form["token"] || ""} onChange={handleChange} />
                      </td>
                    </tr>
                  </>
                )}

                <tr>
                  <td style={{ width: "40%" }}>Deposits:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="deposits" value={form["deposits"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Cash Balance:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="cashBalance" value={form["cashBalance"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Options:</td>
                  <td style={{ width: "60%" }}>
                    {form.default ? (
                      <span className="stock-account-tag" onClick={() => setForm({ ...form, default: false })}>
                        Default
                      </span>
                    ) : (
                      <span className="stock-account-tag-off" onClick={() => setForm({ ...form, default: true })}>
                        Default
                      </span>
                    )}

                    {form.stockAsset ? (
                      <span className="stock-account-tag" onClick={() => setForm({ ...form, stockAsset: false })}>
                        STOCK
                      </span>
                    ) : (
                      <span className="stock-account-tag-off" onClick={() => setForm({ ...form, stockAsset: true })}>
                        STOCK
                      </span>
                    )}

                    {form.cryptoAsset ? (
                      <span className="stock-account-tag" onClick={() => setForm({ ...form, cryptoAsset: false })}>
                        Crypto
                      </span>
                    ) : (
                      <span className="stock-account-tag-off" onClick={() => setForm({ ...form, cryptoAsset: true })}>
                        Crypto
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <p />

            {formType === "add" && (
              <div className="bottons-divider">
                <div onClick={() => addAcc()} className="general-button-small">
                  Add
                </div>
                <div onClick={() => setFormType("")} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
            {formType === "edit" && (
              <div className="bottons-divider">
                <div onClick={() => editAcc()} className="general-button-small">
                  Save
                </div>
                <div onClick={() => setFormType("")} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AddAccount;
