import axios from "axios";
import { processDate, getMonday, fNK } from "./baseFunctions";

const handleStockMarketState = (stock) => {
  let last = 0,
    change = 0,
    changePercent = 0;

  switch (stock.marketState) {
    case "PREPRE":
      last = stock.postMarketPrice || stock.regularMarketPrice;
      change = stock.postMarketChange || 0;
      changePercent = stock.postMarketChangePercent || 0;
      break;
    case "PRE":
      last = stock.preMarketPrice || stock.regularMarketPrice;
      change = stock.preMarketChange || 0;
      changePercent = stock.preMarketChangePercent || 0;
      break;
    case "REGULAR":
      last = stock.regularMarketPrice || 0;
      change = stock.regularMarketChange || 0;
      changePercent = stock.regularMarketChangePercent || 0;
      break;
    case "CLOSED":
      last = stock.regularMarketPrice || 0;
      change = stock.regularMarketChange || 0;
      changePercent = stock.regularMarketChangePercent || 0;
      break;
    case "POST":
      last = stock.postMarketPrice || stock.regularMarketPrice;
      change = (stock.postMarketChange || 0) + stock.regularMarketChange;
      changePercent = (stock.postMarketChangePercent || 0) + stock.regularMarketChangePercent;
      break;
    case "POSTPOST":
      last = stock.postMarketPrice || stock.regularMarketPrice;
      change = (stock.postMarketChange || 0) + stock.regularMarketChange;
      changePercent = (stock.postMarketChangePercent || 0) + stock.regularMarketChangePercent;
      break;
    default:
      last = 0;
      change = 0;
      changePercent = 0;
  }

  return { last, change, changePercent };
};

export const calcStockGroup = async (groupStocks, netLiq) => {
  return groupStocks.reduce((acc, item) => acc + item.value, 0);
};

export const getDefaultStockAccount = (accounts) => {
  let defaultAccountID;
  let defaultAccountData;
  for (const [key, value] of Object.entries(accounts)) {
    if (value.default) {
      defaultAccountID = key;
      defaultAccountData = value;
      break;
    }
  }
  return { id: defaultAccountID, data: defaultAccountData };
};

const getStockData = async (remoteConfig, symbols, userID, marketSummary = false) => {
  const url = `${remoteConfig.stockURL}/stock?sym=${symbols}&appToken=${userID}${marketSummary ? "&market=true" : ""}`;
  try {
    return await axios(url);
  } catch (e) {
    console.log("Error getting Live Stock: ", e.message);
  }
};

export const getStockInfo = async (userID, remoteConfig, rawPositions, accountType, deposits, endingCash) => {
  const stockInfoArray = [];
  const stockInfoStats = {
    total: Number(endingCash),
    deposits,
    costBasis: 0,
    profits: 0,
    numberOfStocks: 0,
    dailyPNL: 0,
    stocksROI: 0,
    endingCash,
  };
  const symbols = [];
  const positions = [];

  rawPositions.forEach((item) => {
    symbols.push(item.symbol);
    positions.push({
      fbID: item.fbID,
      symbol: item.symbol,
      position: item.position,
      costBasis: Number(item.costBasisPrice),
      desc: item.description,
    });
  });

  const stockData = await getStockData(remoteConfig, symbols, userID);

  positions.forEach((item) => {
    const stock = stockData.data.stocks.find((data) => data.symbol === item.symbol.toUpperCase());
    if (stock) {
      const { last, change, changePercent } = handleStockMarketState(stock);

      stockInfoArray.push({
        ...item,
        last,
        change,
        changePercent,
        open: stock.regularMarketOpen,
        close: stock.regularMarketPreviousClose,
        high: stock.regularMarketDayHigh,
        low: stock.regularMarketDayLow,
        week_52_high: stock.fiftyTwoWeekHigh,
        week_52_low: stock.fiftyTwoWeekLow,
        description: stock.longName,
        type: stock.quoteType,
        forwardPE: stock.forwardPE || 0,
        preMarketPrice: stock.preMarketPrice || 0,
        preMarketChange: stock.preMarketChange || 0,
        preMarketChangePercent: stock.preMarketChangePercent || 0,
        marketState: stock.marketState,
      });
      stockInfoStats.total += item.position * last;
      stockInfoStats.numberOfStocks += 1;
      stockInfoStats.costBasis += item.position * item.costBasis;
      stockInfoStats.dailyPNL += item.position * change;
    }
  });

  stockInfoStats.profits = deposits - stockInfoStats.costBasis;
  stockInfoStats.stocksROI = ((stockInfoStats.total / deposits) * 100 - 100).toFixed(1);

  return { stocks: stockInfoArray, stats: stockInfoStats };
};

export const getIBKRStockInfo = async (userID, remoteConfig, rawPositions, accountType, deposits, endingCash, stockWatchList) => {
  const stockInfoArray = [];
  const stockWatchInfoArray = [];
  const stockInfoStats = {
    total: Number(endingCash),
    deposits: Number(deposits),
    costBasis: 0,
    profits: 0,
    numberOfStocks: 0,
    dailyPNL: 0,
    stocksROI: 0,
    totalPNL: 0,
    totalMktValue: 0,
    totalUnrealized: 0,
    totalLoss: 0,
  };

  // Amending the irregular stock names
  const index = rawPositions.findIndex((item) => item.symbol.toUpperCase() === "BRK B");
  if (rawPositions[index] !== undefined) {
    rawPositions[index].symbol = "BRK-B";
  }

  const symbols = rawPositions.map((item) => item.symbol);
  const positions = rawPositions;
  const symbolsWatchSymbols = Object.values(stockWatchList).map((item) => item.symbol);
  const stockWatchInfoArrayTemp = Object.entries(stockWatchList).map(([_, data]) => ({
    symbol: data.symbol,
    watchPrice: Number(data.watchPrice),
    priority: data.priority,
    type: data.type,
  }));
  const streamData = {
    "^GSPC": {
      last: 0,
      change: 0,
      changePercent: 0,
    },
    "^DJI": {
      last: 0,
      change: 0,
      changePercent: 0,
    },
    "^IXIC": {
      last: 0,
      change: 0,
      changePercent: 0,
    },
  };
  const stockEarnings = {
    activate: false,
    today: [],
    tomorrow: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    nextWeek: [],
  };
  const stockDividends = {
    activate: false,
    today: [],
    tomorrow: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    nextWeek: [],
  };

  const [stockData, stockWatchData] = await Promise.all([getStockData(remoteConfig, symbols, userID, true), getStockData(remoteConfig, symbolsWatchSymbols, userID)]);

  stockWatchInfoArrayTemp.forEach((item) => {
    const stock = stockWatchData.data.stocks.find((data) => data.symbol === item.symbol.toUpperCase());
    if (stock) {
      const { last, change, changePercent } = handleStockMarketState(stock);

      const alertType = last <= item.watchPrice && item.type === "BUY" ? "BUY" : last >= item.watchPrice && item.type === "SELL" ? "SELL" : "z";
      const color = alertType === "BUY" ? "red" : alertType === "SELL" ? "green" : "grey";
      stockWatchInfoArray.push({
        symbol: item.symbol,
        last,
        change,
        changePercent,
        watchPrice: item.watchPrice,
        dropPercent: (last / item.watchPrice) * 100 - 100,
        upPercent: (item.watchPrice / last) * 100 - 100,
        priority: item.priority,
        type: item.type,
        alertType,
        color,
      });
    }
  });

  positions.forEach((item) => {
    const stock = stockData.data.stocks.find((data) => data.symbol === item.symbol.toUpperCase());
    if (stock) {
      const { last, change, changePercent } = handleStockMarketState(stock);

      const earnings = processDate(stock.earningsTimestamp);
      const dividends = processDate(stock.dividendDate);
      streamData[item.symbol] = { last: Number(last), change: Number(change), changePercent: Number(changePercent) };

      stockInfoArray.push({
        last,
        change,
        changePercent,
        symbol: item.symbol,
        position: Number(item.position),
        avgPrice: Number(item.costBasisPrice),
        open: stock.regularMarketOpen,
        close: stock.regularMarketPreviousClose,
        high: stock.regularMarketDayHigh,
        low: stock.regularMarketDayLow,
        week_52_high: stock.fiftyTwoWeekHigh,
        week_52_low: stock.fiftyTwoWeekLow,
        yearRange: stock.fiftyTwoWeekRange,
        dayRange: stock.regularMarketDayRange,
        averageAnalystRating: stock.averageAnalystRating,
        forwardPE: stock.forwardPE || 0,
        company: stock.longName,
        type: stock.quoteType,
        pnl: Math.floor(change * item.position),
        unrealized_pnl: Math.floor(Number(last * item.position - item.costBasisPrice * item.position)),
        unrealized_percent: ((Number(last) * item.position - item.costBasisPrice * item.position) / (item.costBasisPrice * item.position)) * 100,
        roi: (((Number(last) * item.position - item.costBasisPrice * item.position) / (item.costBasisPrice * item.position)) * 100).toFixed(2),
        preMarketPrice: stock.preMarketPrice || 0,
        preMarketChange: stock.preMarketChange || 0,
        preMarketChangePercent: stock.preMarketChangePercent || 0,
        marketState: stock.marketState,
        marketValue: item.position * Number(last),
        assetCategory: item.assetCategory,
        description: item.description,
        percentOfNAV: Number(item.percentOfNAV),
        markPrice: Number(item.markPrice) || 0,
        earningsToday: earnings.suppliedDateDayIsToday,
        dividendsToday: dividends.suppliedDateDayIsToday,
        dividendDate: dividends.suppliedDateNYC || "-",
        dividendPercent: stock.trailingAnnualDividendYield ? (stock.trailingAnnualDividendYield * 100).toFixed(2) : 0,
        dividendTotal: stock.trailingAnnualDividendRate ? Math.floor(stock.trailingAnnualDividendRate * item.position) : 0,
      });

      stockInfoStats.total += item.position * last;
      stockInfoStats.numberOfStocks += 1;
      stockInfoStats.costBasis += Number(item.position) * Number(item.costBasisPrice);
      stockInfoStats.dailyPNL += item.position * change;
      stockInfoStats.totalPNL += change * item.position;
      stockInfoStats.totalMktValue += Number(last * item.position);
      stockInfoStats.totalUnrealized += Number(last) * item.position - item.costBasisPrice * item.position;

      if (Number(last * item.position - item.costBasisPrice * item.position) < 0) {
        stockInfoStats.totalLoss += Number(last * item.position - item.costBasisPrice * item.position);
      }
    }
  });
  stockInfoStats.stocksROI = ((stockInfoStats.total / Number(deposits)) * 100 - 100).toFixed(2);

  return {
    stocks: stockInfoArray,
    stockWatch: stockWatchInfoArray,
    marketSummary: stockData.data.marketSummary,
    symbols,
    streamData,
    stockEarnings,
    stockDividends,
    stats: stockInfoStats,
  };
};

export const calcTrades = (trades, accountData) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const monday = getMonday(today).getDate();
  const tradesWTD = trades.filter((item) => {
    const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
    return Number(tradeDate.slice(6, 8)) >= monday && Number(tradeDate.slice(6, 8)) <= monday + 4;
  });

  const tradesAmountWTD = tradesWTD.reduce((acc, item) => acc + Number(item.cost), 0);
  const tradesAmountMTD = trades
    .filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 6) === `${year}${month}`;
    })
    .reduce((acc, item) => acc + Number(item.cost), 0);

  const tradesAmountYTD = trades
    .filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 4) === `${year}`;
    })
    .reduce((acc, item) => acc + Number(item.cost), 0);

  return {
    accountName: accountData.name,
    accountType: accountData.type,
    deposits: fNK(accountData.deposits),
    stockTradesMonthlyBudget: accountData.stockTradesMonthlyBudget,
    tradesAmountWTD: fNK(tradesAmountWTD),
    tradesAmountMTD: tradesAmountMTD,
    tradesAmountYTD: fNK(tradesAmountYTD),
    tradesNumberWTD: tradesWTD.length,
    tradesNumberMTD: trades.filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 6) === `${year}${month}`;
    }).length,
    tradesNumberYTD: trades.filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 4) === `${year}`;
    }).length,
    tradesWTD,
  };
};

export const getBLSData = async (token, range = -18) => {
  try {
    const response = await axios(`https://api.navscape.io/blsdata?appToken=${token}`);
    const blsData = response.data.Results.series;

    const parseData = (series) =>
      series.data.reduce(
        (acc, item) => {
          acc.dates.push(`${item.periodName.slice(0, 3)} ${item.year}`);
          acc.values.push(item.calculations.pct_changes["12"]);
          return acc;
        },
        { dates: [], values: [] }
      );

    const cpiData = parseData(blsData[0]);
    const ppiData = parseData(blsData[1]);
    const joltsData = parseData(blsData[2]);

    return {
      datescpi: cpiData.dates.reverse().slice(range),
      cpi: cpiData.values.reverse().slice(range),
      datesppi: ppiData.dates.reverse().slice(range),
      ppi: ppiData.values.reverse().slice(range),
      datesjolts: joltsData.dates.reverse().slice(range),
      jolts: joltsData.values.reverse().slice(range),
    };
  } catch (e) {
    console.log("BLS DATA Error: ", e);
  }
};
