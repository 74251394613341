import Chart from "react-apexcharts";

const NetWorthChart = ({ privacyMode, cash, assets, stocks, crypto }) => {
  // var series = [500000, 100000, 20000];
  let series = [cash || 25, assets || 25, stocks || 25, crypto || 25];

  var NetWorthPie = {
    legend: {
      show: true,
      position: "bottom",
      offsetX: 0,
      offsetY: -2,
    },
    grid: {
      padding: {
        top: -2,
        bottom: -2,
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: -10,
          minAngleToShowLabel: 20,
        },
        // hollow: {
        //   margin: 10,
        //   size: "40%",
        // },
        // dataLabels: {
        //   showOn: "always",
        //   name: {
        //     offsetY: -11,
        //     show: true,
        //     color: "#888",
        //     fontSize: "11px",
        //   },
        //   value: {
        //     offsetY: -1,
        //     color: "#111",
        //     fontSize: "22px",
        //     show: true,
        //   },
        // },
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Cash", "Assets", "Stocks", "Crypto"],
    colors: ["#33658A", "#F6AE2D", "#2F4757", "#F26418"],
  };

  return (
    <div className="home-secondary-block">
      <div className="home-percentage-block">
        <div className="home-side-percentage">NET.WORTH</div>

        <div style={{ margin: "0px", padding: "0px", justifyContent: "center", display: "flex", zindex: 2 }}>
          {/* <Chart options={NetWorthPie} series={privacyMode ? [0] : series} type="donut" width="150" height="170" /> */}
          <Chart options={NetWorthPie} series={series} type="pie" width="280" height="250" style={{ zindex: 2 }} />
        </div>
      </div>
      <p />
      <p />
    </div>
  );
};

export default NetWorthChart;
