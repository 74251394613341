import { IonIcon, IonButton } from "@ionic/react";
import { folderOutline } from "ionicons/icons";
import { useState } from "react";
import { validateArray } from "../../functions/baseFunctions";

const StockGroupSetter = ({ stockGroups, setFilteredGroupStock, setFilteredStock, setActiveGroup }) => {
  const [groupColor, setGroupColor] = useState(false);
  const setGroupStock = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setActiveGroup(value);
    setFilteredStock("");
    let arr = [];
    arr = value.split(",");
    const validArr = validateArray(arr);
    if (value === "all") {
      setGroupColor(false);
      setFilteredGroupStock([]);
    } else {
      setGroupColor(true);
      setFilteredGroupStock(validArr);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "left", maxWidth: "280px" }}>
      <select name={""} className="stock-group-setter" onChange={setGroupStock}>
        <option value="" style={groupColor ? { backgroundColor: "yellow" } : null}>
          Stock Groups
        </option>
        <option value="all">All Stocks</option>
        {stockGroups &&
          Object.entries(stockGroups).length > 0 &&
          Object.entries(stockGroups).map((item) => {
            let theKey = item[0];
            let theData = item[1];
            return (
              <option key={theKey} value={theData.symbols}>
                {theData.name}
              </option>
            );
          })}
      </select>
      <IonButton routerLink="/stocks/groups" fill="clear" style={{ margin: "0px", marginLeft: "-12px" }}>
        <IonIcon icon={folderOutline} style={{ fontSize: "24px", color: "darkgray" }} />
      </IonButton>
    </div>
  );
};

export default StockGroupSetter;
