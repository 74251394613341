import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from "@ionic/react";

import StockTradesTable from "./StockTradesTable";

const StockTrades = ({ data, filteredStock, count, title, privacyMode }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/stocks" />
          </IonButtons>
          <IonTitle>Stock Trades</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StockTradesTable data={data} filteredStock={filteredStock} count={count} title={title} privacyMode={privacyMode} />
      </IonContent>
    </IonPage>
  );
};

export default StockTrades;
