import React from "react";
import { IonIcon } from "@ionic/react";
import { logoAppleAr, logoElectron } from "ionicons/icons";

const formatNumber = (num: any) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

const CryptoStats: React.FC<any> = ({ homeState, cryptoTotal, privacyMode, cryptoROI, brokers, coins }): JSX.Element => {
  return (
    <div className="icons-top-wrapper">
      <div className="num-coins">
        <IonIcon icon={logoAppleAr} size="large" style={{ paddingLeft: "10px" }} />
        <br />
        <b>{coins}</b> x COINS
      </div>
      <div className="num-coins">
        <span style={{ fontSize: "12px", letterSpacing: "1px" }}>ROI / Total</span>
        <br />
        <div className="crypto-top-roi">{privacyMode ? "**" : cryptoROI}%</div>
        {homeState && <span style={{ fontSize: "18px", fontWeight: 500, lineHeight: "0.1px", letterSpacing: "0.5px" }}>${formatNumber(Math.floor(cryptoTotal))}</span>}
      </div>
      <div className="num-last">
        <IonIcon icon={logoElectron} size="large" style={{ paddingLeft: "10px" }} />
        <br />
        <b>{brokers}</b> x BROKERS
      </div>
    </div>
  );
};

export default CryptoStats;
