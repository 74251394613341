import React from "react";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";

const CryptoNews: React.FC<any> = ({ cryptoNews }): JSX.Element => {
  return (
    <>
      {cryptoNews.length > 0 &&
        // cryptoNews.slice(0, 20).map((item: any, index: any) => (
        cryptoNews.map((item: any, index: any) => (
          <div className="crypto-news" key={index}>
            <div className="crypto-news-title">{item.title}</div>
            {/* <div className="crypto-news-icon">
              <IonIcon icon={caretForwardOutline} size="10px" style={{ marginBottom: "-4px", marginRight: "4px" }} />
            </div> */}
          </div>
        ))}
    </>
  );
};

export default CryptoNews;
