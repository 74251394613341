import { IonCol, IonContent, IonPage, IonRow, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from "@ionic/react";

import { dForm } from "../../functions/baseFunctions";

const StockTransactions = ({ data, filteredStock, count }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/stocks" />
          </IonButtons>
          <IonTitle>Transactions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            <div style={{ textAlign: "center" }}>
              <table className="stock-main-table">
                <thead>
                  <tr className="stock-trades-header">
                    <th>Stock</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data
                      .sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1))
                      .filter((val) => {
                        if (filteredStock === "") {
                          return val;
                        } else if (val.symbol.includes(filteredStock)) {
                          return val;
                        }
                      })
                      .slice(0, count)
                      .map((item, index) => (
                        <tr className="stock-trades-item" key={index}>
                          <td style={{ textAlign: "left" }}>
                            <b>{item.symbol}</b>
                          </td>
                          <td>{item.type}</td>
                          <td style={Number(item.amount) > 0 ? { textAlign: "left", color: "green" } : { textAlign: "left", color: "red" }}>${<b>{item.amount}</b>}</td>
                          <td style={{ textAlign: "left" }}>{dForm(item.dateTime)}</td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default StockTransactions;
