import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RefresherEventDetail } from "@ionic/core";
import { useDispatch } from "react-redux";
import { setDataState } from "../redux/actions";
import { IonGrid, IonPopover, IonCol, IonRow, IonContent, IonPage, IonIcon, IonRefresher, IonRefresherContent, IonToolbar } from "@ionic/react";
import { refreshOutline, fingerPrintOutline, ellipsisVerticalOutline, arrowBackOutline, addOutline } from "ionicons/icons";

import CryptoStats from "../components/crypto/CryptoStats";
import CryptoPanels from "../components/crypto/CryptoPanels";
import CryptoDetails from "../components/crypto/CryptoDetails.jsx";
import CryptoNews from "../components/crypto/CryptoNews";
import CryptoTopCoins from "../components/crypto/CryptoTopCoins";
import CryptoWatchList from "../components/crypto/CryptoWatchList";
import { getTopCryptoData } from "../functions/funcCrypto";
import { fN } from "../functions/baseFunctions";
import { getData } from "../functions/funcFirebase";
import "./Crypto.css";
import NavWrap from "../components/stocks/NavWrap";

const Crypto: React.FC = () => {
  const dispatch = useDispatch();
  const userID = useSelector((state: any) => state.user.uid);
  const userData = useSelector((state: any) => state.data);
  const remoteConfig = useSelector((state: any) => state.remoteConfig);
  const [cryptoWatchList, setCryptoWatchList] = useState([]);
  const [cryptoNews, setCryptoNews] = useState([]);
  const [cryptoTopCrypto, setTopCrypto] = useState([]);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [secondaryPanel, setSecondaryPanel] = useState("mycrypto");
  const [reloading, setReloading] = useState(false);
  const [cryptoAlertsMenu, setCryptoAlertsMenu] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [refreshCryptoData, setRefreshCryptoData] = useState(false);
  const [cryptoInfoStats, setCryptoInfoStats]: any = useState({
    cryptoTotal: 0,
    cryptoInvested: 0,
    cryptoProfits: 0,
    numberOfStocks: 0,
    dailyPNL: 0,
    numberOfBrokers: 0,
    numberOfCoins: 0,
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    refreshCrypto();
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  };

  const refreshCrypto = () => {
    setCryptoWatchList([]);
    setCryptoInfoStats({
      cryptoTotal: 0,
      cryptoInvested: 0,
      cryptoProfits: 0,
      cryptoROI: 0,
      numberOfStocks: 0,
      cryptoDailyPNL: 0,
      numberOfBrokers: 0,
      numberOfCoins: 0,
    });
    getWatchList();
    setRefreshCryptoData(true);

    secondaryPanel === "topnews" && getCryptoNews();
    secondaryPanel === "topcrypto" && getTopCrypto();
  };

  const getCryptoNews = async () => {
    const news = await axios.get(`https://min-api.cryptocompare.com/data/v2/news/?lang=EN`);
    setCryptoNews(news.data.Data);
  };

  const getTopCrypto = async () => {
    setTopCrypto(await getTopCryptoData(userID, remoteConfig));
  };

  const getWatchList = async () => {
    try {
      userData.cryptoWatchList &&
        userData.cryptoWatchList.length > 0 &&
        userData.cryptoWatchList.map(async (item: any) => {
          let SYM = item.symbol.toUpperCase();
          const watchListData = await axios.get(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${SYM}&tsyms=USD`);
          setCryptoWatchList((cryptoWatchList): any => [
            ...cryptoWatchList,
            {
              symbol: SYM,
              price: watchListData.data.RAW[SYM].USD.PRICE,
              watchPrice: item.watchPrice,
            },
          ]);
        });
    } catch (e: any) {
      console.log(e.message);
    }
  };

  // Refresh the Firebase Data
  const refreshFBData = async () => {
    const dataRes = await getData({ uid: userID });
    if (dataRes.status === "success") {
      dispatch(setDataState(dataRes.data));
      refreshCrypto();
    } else {
      console.log("Firebase Refresh Error Error");
    }
  };

  useEffect(() => {
    setRefreshCryptoData(true);
    setCryptoWatchList([]);
    setCryptoInfoStats({
      cryptoTotal: 0,
      cryptoInvested: 0,
      cryptoProfits: 0,
      numberOfStocks: 0,
      dailyPNL: 0,
      numberOfBrokers: 0,
      numberOfCoins: 0,
    });
    getCryptoNews();
    getTopCrypto();
    getWatchList();
  }, []);

  return (
    <IonPage>
      {/* <IonToolbar mode="ios" id="cry"></IonToolbar> */}

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonGrid className="ion-no-padding">
          <Fragment>
            <div className="the-top-container">
              <div className="crypto-header">
                <IonRow>
                  <IonCol size="12">
                    <div className="top-title">
                      <IonIcon icon={arrowBackOutline} size="14px" style={{ marginBottom: "-4px", marginRight: "4px" }} />
                      CRYPTO
                      <div style={{ marginTop: "1px", marginRight: "0px", float: "right" }}>
                        {privacyMode && <span className="crypto-privacy-mode">PRIVACY ON</span>}
                        <IonIcon icon={addOutline} onClick={() => setAddForm(true)} style={{ paddingLeft: "10px" }} />
                        <IonIcon icon={fingerPrintOutline} onClick={() => setPrivacyMode(!privacyMode)} style={{ paddingLeft: "12px" }} />
                        <IonIcon icon={refreshOutline} onClick={() => refreshFBData()} style={{ paddingLeft: "12px" }} />
                        <IonIcon icon={ellipsisVerticalOutline} onClick={() => setCryptoAlertsMenu(!cryptoAlertsMenu)} style={{ paddingLeft: "6px", paddingRight: "0px" }} />
                        <IonPopover isOpen={cryptoAlertsMenu}>
                          <IonContent>
                            Popover Content Yalla beena
                            <br />
                            Yall
                          </IonContent>
                        </IonPopover>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>

                <div className="crypto-top-stats">
                  <div className="crypto-top-invested">
                    <span style={{ float: "left", fontSize: "14px", paddingRight: "6px", fontWeight: 250 }}>
                      Invested <br />
                      <b>${privacyMode ? "*****" : fN(Math.floor(cryptoInfoStats.cryptoInvested))}</b>
                    </span>
                  </div>

                  <div className="crypto-top-total">
                    <span style={{ fontSize: "22px", fontWeight: 400, marginTop: "-10px" }}>$</span>
                    {privacyMode ? "*****" : fN(Math.floor(cryptoInfoStats.cryptoTotal))}
                  </div>

                  <div className="crypto-top-profits">
                    <span style={{ float: "right", fontSize: "14px", paddingRight: "6px", fontWeight: 250 }}>
                      Profits <br />
                      <b>${privacyMode ? "*****" : fN(Math.floor(cryptoInfoStats.cryptoProfits))}</b>
                    </span>
                  </div>
                </div>
              </div>

              <CryptoStats privacyMode={privacyMode} cryptoROI={cryptoInfoStats.cryptoROI} brokers={cryptoInfoStats.numberOfBrokers} coins={cryptoInfoStats.numberOfCoins} />

              <CryptoPanels secondaryPanel={secondaryPanel} setSecondaryPanel={setSecondaryPanel} />
              <p />
              <NavWrap data={userData.cryptoAutoNAV} color={"#c0392b"} type={"crypto"} deposits={0} />

              {secondaryPanel === "watchlist" && cryptoWatchList.length > 0 && <CryptoWatchList cryptoWatchList={cryptoWatchList} />}
              {secondaryPanel === "topnews" && cryptoNews.length > 0 && <CryptoNews cryptoNews={cryptoNews} />}
              {secondaryPanel === "topcrypto" && cryptoTopCrypto.length > 0 && <CryptoTopCoins cryptoTopCrypto={cryptoTopCrypto} />}

              {secondaryPanel === "mycrypto" && (
                <CryptoDetails
                  userID={userID}
                  refreshCryptoData={refreshCryptoData}
                  setRefreshCryptoData={setRefreshCryptoData}
                  setCryptoInfoStats={setCryptoInfoStats}
                  remoteConfig={remoteConfig}
                  reloading={reloading}
                  privacyMode={privacyMode}
                  addForm={addForm}
                  setAddForm={setAddForm}
                  refreshFBData={refreshFBData}
                />
              )}
            </div>
          </Fragment>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Crypto;
