import { useState, useEffect } from "react";
import { IonModal, IonButton, IonContent, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonHeader, IonToolbar, IonTitle, IonButtons } from "@ionic/react";

import { pushRecord, editChildData } from "../../functions/funcFirebase";

const BucketAddEdit = ({ userID, addBucket, setAddBucket, getItems, action, bucketData, bucketKey }) => {
  const itemName = "buckets";

  const [form, setForm] = useState({
    name: "",
    desc: "",
    duration: 0,
    targetValue: 0,
    currentValue: 0,
    term: "short",
    percent: 0,
    color: "gray",
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setForm({
      ...form,
      [name]: type === "number" ? Number(value) : value,
    });
  };

  const submitForm = () => {
    pushRecord(userID, itemName, form);
    getItems();
    setAddBucket(false);
  };

  const editItem = async () => {
    editChildData(userID, `/${itemName}/${bucketKey}/`, form);
    getItems();
    setAddBucket(false);
  };

  useEffect(() => {
    if (action === "edit") {
      setForm(bucketData);
    }
  }, [bucketData]);

  return (
    <IonModal isOpen={addBucket}>
      <IonHeader>
        <IonToolbar>
          {action === "edit" && <IonTitle>Edit Bucket</IonTitle>}
          {action === "add" && <IonTitle>Add a Bucket</IonTitle>}
          <IonButtons slot="end">
            <IonButton color="light" onClick={() => setAddBucket(false)}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">Name</IonLabel>
          <IonInput value={form.name} name="name" onIonChange={handleChange}></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
          <IonInput value={form.desc} name="desc" onIonChange={handleChange}></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Target Value (in $USD)</IonLabel>
          <IonInput value={form.targetValue} type="number" name="targetValue" onIonChange={handleChange}></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Duration (in years)</IonLabel>
          <IonInput value={form.duration} name="duration" type="number" onIonChange={handleChange}></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Term</IonLabel>
          <IonSelect value={form.term} name="term" placeholder="Select One" onIonChange={handleChange}>
            <IonSelectOption value={"short"}>Short Term</IonSelectOption>
            <IonSelectOption value={"mid"}>Mid. Term</IonSelectOption>
            <IonSelectOption value={"long"}>Long Term</IonSelectOption>
          </IonSelect>
        </IonItem>

        {action === "add" && (
          <IonButton size="small" onClick={() => submitForm()}>
            Add
          </IonButton>
        )}
        {action === "edit" && (
          <IonButton size="small" onClick={() => editItem()}>
            Save
          </IonButton>
        )}
      </IonContent>
    </IonModal>
  );
};

export default BucketAddEdit;
