import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { fN } from "../../functions/baseFunctions";

const AssetsItems = ({ theKey, theData, editItem, deleteAsset, trashOutline, present, privacyMode }) => {
  return (
    <IonItem>
      <IonLabel>
        <strong>{theData.name}</strong>

        <p>{theData.desc}</p>
      </IonLabel>
      <span style={{ fontSize: "18px" }}>
        <b>${privacyMode ? "***" : fN(Math.floor(theData.value))}</b>
      </span>

      <span onClick={() => editItem(theKey)} className="fire-item-header-tag-active">
        Edit
      </span>
      <IonIcon
        icon={trashOutline}
        onClick={() =>
          present({
            cssClass: "my-css",
            header: "Delete",
            message: "Are you sure you want to delete this item?",
            buttons: ["Cancel", { text: "Delete", handler: (d) => deleteAsset(theKey) }],
          })
        }
        style={{ paddingLeft: "6px", color: "darkred", fontSize: "18px" }}
      />
    </IonItem>
  );
};

export default AssetsItems;
