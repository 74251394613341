import { IonRow, IonCol } from "@ionic/react";
import { fNK } from "../../functions/baseFunctions";

const BucketsList = ({ bucketsData, setShowBucket }) => {
  return (
    <IonRow>
      <IonCol size="12">
        <div style={{ display: "grid", placeItems: "center" }}>
          {bucketsData &&
            bucketsData.length > 0 &&
            bucketsData.map((item) => {
              let theKey = item[0];
              let theData = item[1];
              return (
                <div key={theKey} className="bucket-item" onClick={() => setShowBucket(theKey)}>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "18px", fontWeight: 500, width: "60%" }}>
                      {theData.name && theData.name.toUpperCase()}

                      <div>
                        <span className="stock-earnings-item-tag-tomorrow" style={{ backgroundColor: "#34495e" }}>
                          {theData.term} Term
                        </span>
                        <span className="stock-earnings-item-tag-today">
                          <b>{theData.duration}</b>/Yrs
                        </span>
                      </div>

                      <div style={{ fontSize: "12px", fontWeight: 400, marginTop: "4px", color: "grey" }}>{theData.desc}</div>
                    </div>

                    <div>
                      <span style={{ justifyContent: "right", fontSize: "22px", fontWeight: 500, color: theData.color || "black" }}>{theData.percent}%</span>
                      {/* {theData.percent}% */}
                      <br />
                      <div style={{ fontSize: "12px", fontWeight: 400, marginTop: "4px" }}>
                        <b>CURRENT: ${fNK(theData.currentValue)}</b>
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 400, marginTop: "4px" }}>TARGET: {fNK(theData.targetValue)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </IonCol>
    </IonRow>
  );
};

export default BucketsList;
