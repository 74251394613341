const AssetsNotAddedYet = ({ setFormType }) => {
  return (
    <>
      <br />
      <br />
      <div style={{ textAlign: "center", fontSize: "16px", fontWeight: 500, color: "darkgray" }}>No Assets Added Yet</div>
      <br />
      <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
        <div onClick={() => setFormType("add")} className="general-button" style={{ width: "200px" }}>
          Add Asset
        </div>
      </div>
    </>
  );
};

export default AssetsNotAddedYet;
