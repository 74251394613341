import { IonRow, IonCol, IonIcon } from "@ionic/react";
import { fingerPrintOutline, addOutline, fileTrayStacked, refresh } from "ionicons/icons";

const BucketsHeader = ({ setPrivacyMode, privacyMode, setAddBucket, getItems }) => {
  return (
    <div className="the-top-container">
      <div className="stocks-header">
        <IonRow>
          <IonCol size="12">
            <div className="top-title">
              <IonIcon icon={fileTrayStacked} style={{ marginBottom: -3 }} />
              <span style={{ fontWeight: 500, letterSpacing: "0.5px", fontSize: "30px" }}> Buckets</span>

              <div style={{ marginTop: "1px", marginRight: "0px", float: "right" }}>
                {privacyMode && <span className="crypto-privacy-mode">PRIVACY ON</span>}
                <IonIcon icon={fingerPrintOutline} onClick={() => setPrivacyMode(!privacyMode)} style={{ paddingLeft: "12px" }} />
                <IonIcon icon={addOutline} style={{ paddingLeft: "12px" }} onClick={() => setAddBucket(true)} />
                <IonIcon icon={refresh} style={{ paddingLeft: "6px", paddingRight: "0px" }} onClick={() => getItems()} />
                <p />
              </div>
            </div>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

export default BucketsHeader;
