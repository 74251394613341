import { Fragment } from "react";
import { editChildData } from "../../functions/funcFirebase";
import { IonButton, useIonAlert } from "@ionic/react";

const BucketDetail = ({ userID, itemName, getItems, bucketsData, setShowBucket, showBucket, setAddBucket, setBucketData, setAction, setBucketKey }) => {
  const [present] = useIonAlert();

  const deleteFire = (fireKey) => {
    editChildData(userID, `/${itemName}/${fireKey}/`, null);
    getItems();
  };

  return (
    <Fragment>
      {bucketsData &&
        bucketsData.length > 0 &&
        bucketsData.map((item) => {
          let theKey = item[0];
          let theData = item[1];

          return (
            theKey === showBucket && (
              <Fragment key={theKey}>
                <div>
                  Showing Bucket details.
                  <br />
                  name: {theData.name}
                  <br />
                  desc: {theData.desc}
                  <br />
                  <br />
                  <IonButton
                    size="small"
                    onClick={() => {
                      setBucketKey(theKey);
                      setBucketData(theData);
                      setAction("edit");
                      setAddBucket(true);
                    }}
                  >
                    Edit
                  </IonButton>
                  <IonButton size="small" onClick={() => setShowBucket(false)}>
                    Close
                  </IonButton>
                  <IonButton
                    size="small"
                    color="danger"
                    onClick={() =>
                      present({
                        cssClass: "my-css",
                        header: "Delete",
                        message: "Are you sure you want to delete this?",
                        buttons: ["Cancel", { text: "Delete", handler: (d) => deleteFire(theKey) }],
                      })
                    }
                  >
                    Delete
                  </IonButton>
                </div>
              </Fragment>
            )
          );
        })}
    </Fragment>
  );
};

export default BucketDetail;
