import { IonIcon } from "@ionic/react";
import { reorderThreeOutline, reorderThreeSharp, removeOutline, removeSharp, optionsOutline, optionsSharp } from "ionicons/icons";

const StocksTableMode = ({ stockSettings, setStockSettings, setStockSettingsPanel, stockSettingsPanel }) => {
  const handleOption = (option, value) => {
    localStorage.setItem(`stockSettings.${option}`, value);
    if (value === "compact") {
      setStockSettings({
        ...stockSettings,
        [option]: value,
        last: true,
        change: true,
        changePercent: true,
        position: true,
        unrealized_pnl: true,
        pnl: true,

        avgPrice: false,
        marketValue: false,
        unrealized_percent: false,
        percentOfNAV: false,
        dividendPercent: false,
        dividendTotal: false,
        averageAnalystRating: false,
        dividendDate: false,
        forwardPE: false,
      });
    }

    if (value === "wide") {
      setStockSettings({
        ...stockSettings,
        [option]: value,
        last: true,
        change: true,
        changePercent: true,
        position: true,
        pnl: true,
        forwardPE: true,

        avgPrice: true,
        marketValue: true,
        unrealized_pnl: true,
        unrealized_percent: true,
        percentOfNAV: true,
        dividendPercent: false,
        dividendTotal: false,
        dividendDate: false,
        averageAnalystRating: true,
      });
    }
  };
  return (
    <div className="stock-table-mode-wrapper">
      <IonIcon
        icon={stockSettingsPanel ? optionsSharp : optionsOutline}
        className={stockSettingsPanel ? "stock-table-mode-active" : "stock-table-mode"}
        onClick={() => setStockSettingsPanel(!stockSettingsPanel)}
      />
      <IonIcon
        icon={stockSettings.mode === "compact" ? removeSharp : removeOutline}
        onClick={() => handleOption("mode", "compact")}
        className={stockSettings.mode === "compact" ? "stock-table-mode-active" : "stock-table-mode"}
      />

      <IonIcon
        icon={stockSettings.mode === "wide" ? reorderThreeSharp : reorderThreeOutline}
        onClick={() => handleOption("mode", "wide")}
        className={stockSettings.mode === "wide" ? "stock-table-mode-active" : "stock-table-mode"}
      />
    </div>
  );
};

export default StocksTableMode;
