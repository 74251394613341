import { useEffect, useState } from "react";
import { IonInput, IonItem, IonLabel, IonButton, useIonAlert } from "@ionic/react";

const FireEditForm = ({ fireKey, activeFireKey, fireEditActive, setFireEditActive, data, handleFireUpdate, deleteFire }) => {
  const [form, setForm] = useState({});
  const [present] = useIonAlert();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    handleFireUpdate(fireKey, form);
    setFireEditActive(false);
  };

  const handleDelete = () => {
    deleteFire(fireKey);
    setFireEditActive(false);
  };

  useEffect(() => {
    setForm(data);
  }, []);

  return (
    fireEditActive &&
    fireKey === activeFireKey && (
      <>
        <IonItem>
          <IonLabel name="name">Name</IonLabel>
          <IonInput
            style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
            name="name"
            value={form["name"] || ""}
            placeholder="Enter Input"
            onIonChange={handleChange}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel name="desc">Desc.</IonLabel>
          <IonInput
            style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
            name="desc"
            value={form["desc"] || ""}
            placeholder="Enter Input"
            onIonChange={handleChange}
          ></IonInput>
        </IonItem>
        <IonButton size="small" onClick={() => handleUpdate()}>
          Save
        </IonButton>
        <IonButton
          size="small"
          color="danger"
          onClick={() =>
            present({
              cssClass: "my-css",
              header: "Delete",
              message: "Are you sure you want to delete this?",
              buttons: ["Cancel", { text: "Delete", handler: (d) => handleDelete() }],
            })
          }
        >
          Delete
        </IonButton>
        <IonButton size="small" onClick={() => setFireEditActive(false)}>
          Close
        </IonButton>
      </>
    )
  );
};

export default FireEditForm;
