import { IonIcon } from "@ionic/react";
import { cashOutline, buildOutline, diamondOutline, pulseOutline, shieldHalfOutline } from "ionicons/icons";
import { fN, fNK, fNKL } from "../../functions/baseFunctions";

const StockCashReport = ({
  privacyMode,
  activeStockAccount,
  accountID,
  accountCashData,
  totalPNL,
  streamTotalPNL,
  totalUnrealized,
  streamingMode,
  totalMktValue,
  deposits,
  totalLoss,
}) => {
  return (
    activeStockAccount &&
    activeStockAccount.type === "IBKR" && (
      <div className="stocks-top-stats-two">
        <div className="stocks-top-stats-two-first">
          <div>
            <IonIcon icon={shieldHalfOutline} />
            <div className="stock-top-stats-title">Dividends YTD</div>
            <div className="stock-top-stats-value">
              {privacyMode
                ? "***"
                : accountID && fN(Math.floor(Number(accountCashData.dividendsYTD) + Number(accountCashData.paymentInLieuYTD) + Number(accountCashData.withholdingTaxYTD)))}
              {/* accountID && fN(Math.floor(Number(accountCashData.dividends) + Number(accountCashData.paymentInLieu) + Number(accountCashData.withholdingTax)))} */}
            </div>
            <div className="stock-top-stats-title">Dividends MTD</div>
            <div className="stock-top-stats-value">
              {privacyMode
                ? "***"
                : accountID && fN(Math.floor(Number(accountCashData.dividendsMTD) + Number(accountCashData.paymentInLieuMTD) + Number(accountCashData.withholdingTaxMTD)))}
            </div>
          </div>
          <div>
            <IonIcon icon={buildOutline} />
            <div className="stock-top-stats-title">Commiss. YTD</div>
            <div className="stock-top-stats-value">{privacyMode ? "***" : accountID && fN(Math.floor(Number(accountCashData.commissionsYTD)))}</div>
            {/* <div className="stock-top-stats-value">{privacyMode ? "***" : accountID && fN(Math.floor(Number(accountCashData.commissions)))}</div> */}
            <div className="stock-top-stats-title">Commiss. MTD</div>
            <div className="stock-top-stats-value">{privacyMode ? "***" : accountID && fN(Math.floor(Number(accountCashData.commissionsMTD)))}</div>
          </div>
        </div>

        <div className="stocks-top-stats-two-center">
          <IonIcon icon={cashOutline} />
          <div className="stock-top-stats-title">Cash Balance</div>
          <div className="stock-top-stats-value">
            {privacyMode ? (
              "***"
            ) : accountID && accountCashData.endingCash >= 0 ? (
              <span style={{ color: "green" }}>{fN(Math.floor(accountCashData.endingCash))}</span>
            ) : (
              <span style={{ color: "red" }}>{accountID && fN(Math.floor(accountCashData.endingCash))}</span>
            )}
          </div>

          {/* BROKEN INTEREST */}
          <div>
            <div className="stock-top-stats-title">Broker Interest</div>
            <div style={{ display: "grid", gridTemplateColumns: "50% 50%", textAlign: "center" }}>
              <div>
                <div className="stock-top-stats-title" style={{ letterSpacing: "0px" }}>
                  <b>YTD</b>
                </div>
                <div className="stock-top-stats-value" style={{ marginTop: "-4px" }}>
                  {privacyMode ? (
                    "***"
                  ) : (
                    <span style={accountID && Number(accountCashData.brokerInterestYTD) >= 0 ? { fontSize: "12px", color: "green" } : { fontSize: "12px", color: "red" }}>
                      {fN(Math.floor(accountCashData.brokerInterestYTD))}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="stock-top-stats-title" style={{ letterSpacing: "0px" }}>
                  <b>MTD</b>
                </div>
                <div className="stock-top-stats-value" style={{ marginTop: "-4px" }}>
                  {privacyMode ? (
                    "***"
                  ) : (
                    <span style={accountID && Number(accountCashData.brokerInterestYTD) >= 0 ? { fontSize: "12px", color: "green" } : { fontSize: "12px", color: "red" }}>
                      {fN(Math.floor(accountCashData.brokerInterestMTD))}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PNL */}
        <div className="stocks-top-stats-two-pnl">
          <div>
            <IonIcon icon={diamondOutline} />
            <div className="stock-top-stats-title">Unrealized P&L</div>
            <div className="stock-top-stats-value">
              {privacyMode ? "***" : <span style={totalUnrealized >= 0 ? { color: "green" } : { color: "red" }}>{fN(Math.floor(totalUnrealized))}</span>}
            </div>

            <div className="stock-top-stats-title">Breakdown</div>

            <div style={{ display: "grid", gridTemplateColumns: "50% 50%", textAlign: "center" }}>
              <div>
                <div className="stock-top-stats-title">
                  <b>Profits</b>
                </div>
                <div className="stock-top-stats-value" style={{ marginTop: "-4px" }}>
                  {privacyMode ? (
                    "***"
                  ) : (
                    <span style={{ fontSize: "12px", color: "green", letterSpacing: "-0.4px" }}>
                      {fNKL(totalMktValue + +accountCashData.endingCash - deposits - totalUnrealized)}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="stock-top-stats-title">
                  <b>Losses</b>
                </div>
                <div className="stock-top-stats-value" style={{ marginTop: "-4px" }}>
                  <span style={{ fontSize: "12px", color: "red", letterSpacing: "-0.4px" }}>{fNKL(Math.floor(+totalLoss))}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <IonIcon icon={pulseOutline} />
            <div className="stock-top-stats-title">Daily P&L</div>
            <div className="stock-top-stats-value">
              {totalPNL >= 0 ? (
                <span style={{ color: "green", backgroundColor: "#f7f7bf" }}>
                  <b>{privacyMode ? "***" : fN(Math.floor(totalPNL))}</b>
                </span>
              ) : (
                <span style={{ color: "red", backgroundColor: "#f7f7bf" }}>{privacyMode ? "***" : fN(Math.floor(totalPNL))}</span>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>{streamingMode && <span className="streaming-mode">STREAMING</span>}</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default StockCashReport;
