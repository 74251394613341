import React, { Fragment } from "react";
import { IonIcon } from "@ionic/react";
import { cloudDownload, pulse } from "ionicons/icons";

const formatNumber = (num: any) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

const CryptoStatsHome: React.FC<any> = ({ homeState, privacyMode, cryptoInfoStats }): JSX.Element => {
  return (
    <Fragment>
      <div className="icons-top-wrapper">
        <div className="num-coins">
          <IonIcon icon={cloudDownload} size="small" style={{ paddingLeft: "10px", color: "grey" }} /> Invested
          <br />
          <div className="item-value">${privacyMode ? "****" : formatNumber(Math.floor(cryptoInfoStats.cryptoInvested))}</div>
        </div>
        <div className="num-coins">
          <span style={{ fontSize: "10px", letterSpacing: "1px" }}>ROI / Total</span>
          <br />
          <div style={{ fontSize: "18px" }}>{privacyMode ? "**" : cryptoInfoStats.cryptoROI}%</div>
          {homeState && (
            <span style={{ fontSize: "20px", fontWeight: 500, lineHeight: "0.1px", letterSpacing: "0.5px" }}>
              ${privacyMode ? "******" : formatNumber(Math.floor(cryptoInfoStats.cryptoTotal))}
            </span>
          )}
        </div>
        <div className="num-last">
          <IonIcon icon={pulse} size="small" style={{ paddingLeft: "10px", color: "grey" }} /> Profits
          <br />
          <span className="item-value">${privacyMode ? "****" : formatNumber(cryptoInfoStats.cryptoProfits)}</span>
          <br />
          <span style={{ fontSize: "12px" }}>
            24h:{" "}
            <span style={cryptoInfoStats.cryptoDailyPNL >= 0 ? { color: "green" } : { color: "red" }}>
              <b>${privacyMode ? "****" : formatNumber(Math.floor(cryptoInfoStats.cryptoDailyPNL))}</b>
            </span>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default CryptoStatsHome;
