export const getAssetsStats = (assetsData) => {
  let assetsStats = {
    total: 0,
    realEstate: 0,
    cash: 0,
    other: 0,
    investment: 0,
    cashFlow: 0,
    interest: 0,
    income: 0,
    totalIncome: 0,
  };
  assetsData &&
    Object.entries(assetsData).forEach((item) => {
      let data = item[1];
      assetsStats.total = assetsStats.total + Number(data.value);

      // Count CashFlow
      if (data.hasCashFlow) {
        assetsStats.cashFlow = assetsStats.cashFlow + Number(data.cashFlow);
        assetsStats.totalIncome = assetsStats.totalIncome + Number(data.cashFlow);
      }

      // Count Interest
      if (data.hasInterest) {
        let interestCalc = 0;
        interestCalc = (Number(data.interest) * Number(data.value)) / 100;
        assetsStats.interest = assetsStats.interest + interestCalc;
        assetsStats.totalIncome = assetsStats.totalIncome + Number(interestCalc);
      }

      // Count Investments
      if (data.countAsInvestment) {
        assetsStats.investment = assetsStats.investment + Number(data.value);
      }
      // Count Types
      if (data.type === "Real-Estate") {
        assetsStats.realEstate = assetsStats.realEstate + Number(data.value);
      } else if (data.type === "Cash") {
        assetsStats.cash = assetsStats.cash + Number(data.value);
      } else if (data.type === "Other") {
        assetsStats.other = assetsStats.other + Number(data.value);
      } else if (data.type === "OrdinaryIncome" || data.type === "EarnedIncome") {
        assetsStats.income = assetsStats.income + Number(data.value);
        assetsStats.totalIncome = assetsStats.totalIncome + Number(data.value);
      }
    });
  return assetsStats;
};
