function adjustDate(date = new Date(), days = 0) {
  const adjustedDate = new Date(date.getTime());
  adjustedDate.setDate(date.getDate() + days);
  return adjustedDate;
}

const getPreviousDay = (date) => adjustDate(date, -1);
const getFollowingDay = (date) => adjustDate(date, 1);

export const fN = (num) => Math.floor(num).toLocaleString();

export const fNK = (num) => {
  const n = Number(num);
  if (n >= 0 && n <= 1) return n.toFixed(2);
  if (n >= 1e6 || n <= -1e6) return (Math.floor(n) / 1e6).toFixed(3) + "M";
  if (n >= 1e3 || n <= -1e3) return (Math.floor(n) / 1e3).toFixed(2) + "K";
  return Math.floor(n).toLocaleString();
};

export const fNKL = (num) => {
  const n = Number(num);
  if (n >= 0 && n <= 1) return n;
  if (n >= 1e6 || n <= -1e6) return (Math.floor(n) / 1e6).toFixed(3) + "M";
  if (n >= 1e3 || n <= -1e3) return (Math.floor(n) / 1e3).toFixed(1) + "K";
  return Math.floor(n).toLocaleString();
};

export const trunc = (str, num) => (str.length <= num ? str : str.slice(0, num) + "..");

export const dForm = (str) =>
  str &&
  str
    .substring(0, 8)
    .match(/.{1,4}/g)
    .join("-")
    .match(/.{1,7}/g)
    .join("-");

export const prepareChartData = async (data, size = 20, deposits, ytd) => {
  const year = new Date().toLocaleDateString("en-US", { year: "2-digit", timeZone: "America/New_York" });
  const datesArray = [];
  const amountsArray = [];
  const roiArray = [];
  let yearFirst;

  Object.entries(data).forEach(([_, theData]) => {
    const dateItem = new Date(theData.date * 1000).toLocaleString("en-us", { month: "short", day: "numeric", year: "2-digit" });
    if (["Jan 1", "Jan 2", "Jan 3"].some((d) => dateItem.startsWith(d) && dateItem.endsWith(year))) yearFirst = dateItem;

    datesArray.push(dateItem);
    amountsArray.push(theData.amount);
    roiArray.push((Number(theData.amount) / Number(deposits)) * 100 - 100);
  });

  if (ytd && yearFirst) {
    const newArray = datesArray.slice(datesArray.indexOf(yearFirst));
    const newArrayLen = newArray.length;
    return { dates: newArray, amounts: amountsArray.slice(-newArrayLen), rois: roiArray.slice(-newArrayLen) };
  }
  return { dates: datesArray.slice(-size), amounts: amountsArray.slice(-size), rois: roiArray.slice(-size) };
};

export const getMonday = (d) => {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  return new Date(date.setDate(diff));
};

export const getNYSETime = () =>
  new Date().toLocaleString("en-US", { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", timeZone: "America/New_York" });

export const processDate = (dateString) => {
  const suppliedDate = new Date(dateString);
  const todayDate = new Date();
  const year = todayDate.toLocaleDateString("en-US", { year: "numeric", timeZone: "America/New_York" });
  const month = Number(todayDate.toLocaleDateString("en-US", { month: "2-digit", timeZone: "America/New_York" }));

  const suppliedDateNYC = suppliedDate.toLocaleDateString("en-US", { timeZone: "America/New_York" }) || "-";
  const suppliedDateDayNYC = suppliedDate.toLocaleDateString("en-US", { day: "2-digit", timeZone: "America/New_York" });
  const suppliedDateMonthNYC = Number(suppliedDate.toLocaleDateString("en-US", { month: "2-digit", timeZone: "America/New_York" }));
  const suppliedDateYearNYC = suppliedDate.toLocaleDateString("en-US", { year: "numeric", timeZone: "America/New_York" });
  const todayNYC = todayDate.toLocaleDateString("en-US", { day: "numeric", timeZone: "America/New_York" });
  const yesterdayNYC = getPreviousDay().toLocaleDateString("en-US", { day: "2-digit", timeZone: "America/New_York" });
  const tomorrowNYC = getFollowingDay().toLocaleDateString("en-US", { day: "2-digit", timeZone: "America/New_York" });

  const options = { hour: "2-digit", minute: "2-digit", timeZone: "America/New_York" };
  const suppliedDateTime = suppliedDate.toLocaleTimeString("en-US", options);

  const diff = todayDate.getDate() - todayDate.getDay() + (todayDate.getDay() === 0 ? -6 : 1); // adjust when day is Sunday
  const monday = new Date(todayDate.setDate(diff)).getDate();
  const friday = monday + 4;

  const suppliedDateDayIsToday = suppliedDateNYC === todayDate.toLocaleDateString("en-US", { timeZone: "America/New_York" });
  const suppliedDateDayIsYesterday = suppliedDateMonthNYC === month && suppliedDateDayNYC === yesterdayNYC;
  const suppliedDateDayIsTomorrow = suppliedDateMonthNYC === month && suppliedDateDayNYC === tomorrowNYC;
  const suppliedDateDayIsPartOfWeek =
    suppliedDateMonthNYC === month &&
    !suppliedDateDayIsToday &&
    !suppliedDateDayIsYesterday &&
    !suppliedDateDayIsTomorrow &&
    suppliedDateDayNYC > todayNYC &&
    suppliedDateDayNYC > tomorrowNYC &&
    suppliedDateDayNYC <= friday;
  const suppliedDateDayIsNextWeek = suppliedDateMonthNYC === month && suppliedDateYearNYC === year && suppliedDateDayNYC > friday && suppliedDateDayNYC <= friday + 7;
  const suppliedDateDayIsPartOfMonth =
    !suppliedDateDayIsToday &&
    !suppliedDateDayIsYesterday &&
    !suppliedDateDayIsTomorrow &&
    !suppliedDateDayIsPartOfWeek &&
    !suppliedDateDayIsNextWeek &&
    suppliedDateMonthNYC === month &&
    suppliedDateYearNYC === year &&
    suppliedDateDayNYC > todayNYC &&
    suppliedDateDayNYC <= 31;

  return {
    year,
    month,
    yesterday: yesterdayNYC,
    today: todayNYC,
    tomorrow: tomorrowNYC,
    monday,
    friday,
    suppliedDate,
    suppliedDateNYC,
    suppliedDateDayNYC,
    suppliedDateDayIsToday,
    suppliedDateDayIsYesterday,
    suppliedDateDayIsTomorrow,
    suppliedDateDayIsPartOfWeek,
    suppliedDateDayIsNextWeek,
    suppliedDateDayIsPartOfMonth,
    suppliedDateFormated: suppliedDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", timeZone: "America/New_York" }),
    suppliedDateWeekDay: suppliedDate.toLocaleDateString("en-US", { weekday: "short", timeZone: "America/New_York" }),
    suppliedDateTime,
  };
};

export const validateArray = (arr) => [...new Set(arr.map((element) => element.trim()))];
