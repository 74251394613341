import { IonCol, IonContent, IonPage, IonRow, IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonButtons, IonBackButton, IonTitle } from "@ionic/react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import StockNews from "./StockNews";

const StockDetails = ({}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userID = useSelector((state) => state.user.uid);
  const remoteConfig = useSelector((state) => state.remoteConfig);
  const [segment, setSegment] = useState("stock");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/stocks" />
          </IonButtons>
          <IonTitle>{params.get("stock")}</IonTitle>
        </IonToolbar>
        <IonSegment value="all">
          <IonSegmentButton value="info" style={segment === "stock" ? { color: "yellow" } : { color: "white" }} onClick={(e) => setSegment(e.target.value)}>
            Stock
          </IonSegmentButton>
          <IonSegmentButton value="transactions" style={segment === "transactions" ? { color: "yellow" } : { color: "white" }} onClick={(e) => setSegment(e.target.value)}>
            Transactions
          </IonSegmentButton>
          <IonSegmentButton value="trades" style={segment === "trades" ? { color: "yellow" } : { color: "white" }} onClick={(e) => setSegment(e.target.value)}>
            Trades
          </IonSegmentButton>
          <IonSegmentButton value="news" style={segment === "news" ? { color: "yellow" } : { color: "white" }} onClick={(e) => setSegment(e.target.value)}>
            News
          </IonSegmentButton>
        </IonSegment>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            {segment === "stock" && <div>Stock Details</div>}
            {segment === "transactions" && <div>Transactions</div>}
            {segment === "trades" && <div>Trades</div>}
            {segment === "news" && <StockNews stock={params.get("stock")} userID={userID} remoteConfig={remoteConfig} />}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default StockDetails;
