const defaultState = {
  user: {},
  data: {},
  remoteConfig: {},
  cryptoData: {},
  stockData: [],
  homeCryptoData: [],
  homeStockData: [],
};

export default function reducer(state = defaultState, { type, payload }: { type: string; payload: any }): any {
  switch (type) {
    case "SET_USER_STATE":
      return { ...state, user: payload };
    case "SET_DATA":
      return { ...state, data: payload };
    case "SET_REMOTE_CONFIG":
      return { ...state, remoteConfig: payload };
    case "SET_CRYPTO_DATA":
      return { ...state, cryptoData: payload };
    case "SET_STOCK_DATA":
      return { ...state, stockData: payload };
    case "SET_HOME_CRYPTO_DATA":
      return { ...state, homeCryptoData: payload };
    case "SET_HOME_STOCK_DATA":
      return { ...state, homeStockData: payload };
  }
  return state;
}
