import { Fragment, useEffect, useState } from "react";

const StockAccount = ({ accounts, activeStockAccount, setActiveStockAccount }) => {
  const [accountsData, setAccountsData] = useState([]);

  const getAcc = async () => {
    setAccountsData(accounts && Object.entries(accounts));
    accountsData &&
      accountsData.length > 0 &&
      accountsData.map((item) => {
        let keyo = item[0];
        let dato = item[1];
        dato.default && setActiveStockAccount({ id: keyo, name: dato.name, type: dato.type, deposits: dato.deposits, default: dato.default });
      });
  };

  const setAcc = (account) => {
    let obj = JSON.parse(account);
    setActiveStockAccount({ id: obj.id, name: obj.name, type: obj.type, deposits: obj.deposits, default: obj.default, cashBalance: obj.cashBalance });
  };

  useEffect(() => {
    getAcc();
  }, [accounts]);

  return (
    <Fragment>
      <div className="stock-account-wrapper">
        <select
          name="accounts"
          style={{ borderRadius: "3px", borderColor: "lightgrey", width: "98%", justifyContent: "center", textAlign: "center", backgroundColor: "white" }}
          onChange={(e) => setAcc(e.target.value)}
        >
          <option className="stock-account" value={activeStockAccount.name}>
            ACCOUNT: {activeStockAccount.name ? activeStockAccount.name.toUpperCase() : "Select an account"}
          </option>
          {accountsData &&
            accountsData.length > 0 &&
            accountsData.map((item) => {
              let keyo = item[0];
              let dato = item[1];

              return (
                <option
                  key={keyo}
                  value={JSON.stringify({ id: keyo, name: dato.name, type: dato.type, deposits: dato.deposits, default: dato.default, cashBalance: dato.cashBalance })}
                >
                  ACCOUNT: {dato.name.toUpperCase()}
                </option>
              );
            })}
        </select>
      </div>
    </Fragment>
  );
};

export default StockAccount;
