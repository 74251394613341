import { fNK } from "../../functions/baseFunctions";
const AssetsStats = ({ assetsStats }) => {
  return (
    <div className="assets-top-stats">
      <div className="assets-top-stats-item">
        Cash Flow
        <br />
        <span className="assets-top-stats-item-value">{fNK(assetsStats.cashFlow)}</span>
      </div>

      <div className="assets-top-stats-item">
        Interest
        <br />
        <span className="assets-top-stats-item-value">{fNK(assetsStats.interest)}</span>
      </div>
      <div className="assets-top-stats-item">
        Income
        <br />
        <span className="assets-top-stats-item-value"> {fNK(assetsStats.income)}</span>
      </div>
      <div className="assets-top-stats-item">
        Total Income
        <br />
        <span className="assets-top-stats-item-value"> {fNK(assetsStats.totalIncome)}</span>
      </div>
      <div className="assets-top-stats-item">
        Total Assets
        <br />
        <span className="assets-top-stats-item-value"> {fNK(assetsStats.total)}</span>
      </div>
    </div>
  );
};

export default AssetsStats;
