const AssetsAddEdit = ({ formType, form, handleChange, setForm, addAsset, editAsset, setFormType }) => {
  return (
    (formType === "add" || formType === "edit") && (
      <div className="stock-account-form-edit">
        <table className="stock-account-table">
          <tbody>
            <tr>
              <td style={{ width: "40%" }}>Name:</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="name" value={form["name"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Description:</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="desc" value={form["desc"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Value:</td>
              <td style={{ width: "60%" }}>
                <input type="text" className="stock-account-input" name="value" value={form["value"] || ""} onChange={handleChange} />
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Type:</td>
              <td style={{ width: "60%" }}>
                <select className="stock-account-input" onChange={(e) => setForm({ ...form, type: e.target.value })}>
                  <option className="stock-account-input" value="Other" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    {form.type}
                  </option>
                  <option className="stock-account-input" value="Real-Estate" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    Real-Estate
                  </option>
                  <option className="stock-account-input" value="Cash" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    Cash
                  </option>
                  <option className="stock-account-input" value="CD" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    CD
                  </option>
                  <option className="stock-account-input" value="OrdinaryIncome" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    Ordinary Income
                  </option>
                  <option className="stock-account-input" value="EarnedIncome" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    Earned Income
                  </option>
                  <option className="stock-account-input" value="Other" onClick={(e) => setForm({ ...form, type: e.target.value })}>
                    Other
                  </option>
                </select>
              </td>
            </tr>

            <tr>
              <td style={{ width: "40%" }}>Options:</td>
              <td style={{ width: "60%" }}>
                {form.type !== "OrdinaryIncome" &&
                  form.type !== "EarnedIncome" &&
                  (form.countAsInvestment ? (
                    <span className="stock-account-tag" onClick={() => setForm({ ...form, countAsInvestment: false })}>
                      Investment
                    </span>
                  ) : (
                    <span className="stock-account-tag-off" onClick={() => setForm({ ...form, countAsInvestment: true })}>
                      Investment
                    </span>
                  ))}

                {form.type !== "OrdinaryIncome" &&
                  form.type !== "EarnedIncome" &&
                  (form.hasInterest ? (
                    <span className="stock-account-tag" onClick={() => setForm({ ...form, hasInterest: false })}>
                      Has Interest
                    </span>
                  ) : (
                    <span className="stock-account-tag-off" onClick={() => setForm({ ...form, hasInterest: true })}>
                      Has Interest
                    </span>
                  ))}

                {form.type !== "OrdinaryIncome" &&
                  form.type !== "EarnedIncome" &&
                  (form.hasCashFlow ? (
                    <span className="stock-account-tag" onClick={() => setForm({ ...form, hasCashFlow: false })}>
                      Has Cash Flow
                    </span>
                  ) : (
                    <span className="stock-account-tag-off" onClick={() => setForm({ ...form, hasCashFlow: true })}>
                      Has Cash Flow
                    </span>
                  ))}
              </td>
            </tr>

            {form.hasInterest && (
              <tr>
                <td style={{ width: "40%" }}>Interest:</td>

                <td style={{ width: "60%" }}>
                  <input type="number" className="stock-account-input" name="interest" value={form["interest"] || ""} onChange={handleChange} />
                </td>
              </tr>
            )}

            {form.hasCashFlow && (
              <tr>
                <td style={{ width: "40%" }}>Cash Flow:</td>

                <td style={{ width: "60%" }}>
                  <input type="number" className="stock-account-input" name="cashFlow" value={form["cashFlow"] || ""} onChange={handleChange} />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <p />

        {formType === "add" && (
          <div className="bottons-divider">
            <div onClick={() => addAsset()} className="general-button-small">
              Add
            </div>
            <div onClick={() => setFormType("")} className="general-button-small-cancel">
              Cancel
            </div>
          </div>
        )}
        {formType === "edit" && (
          <div className="bottons-divider">
            <div onClick={() => editAsset()} className="general-button-small">
              Save
            </div>
            <div onClick={() => setFormType("")} className="general-button-small-cancel">
              Cancel
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default AssetsAddEdit;
