import { IonIcon } from "@ionic/react";
import { fileTrayStacked } from "ionicons/icons";

const BucketsEmpty = ({ setAddBucket }) => {
  return (
    // ####################### NO BUCKETS ADDED YET #######################
    <>
      <br />
      <br />
      <div style={{ textAlign: "center", fontSize: "16px", fontWeight: 500, color: "darkgrey" }}>
        <IonIcon icon={fileTrayStacked} style={{ justifyContent: "center", fontSize: "60px", color: "#e2e2e2" }} />
        <br />
        No Buckets Added Yet
      </div>
      <br />
      <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
        <div onClick={() => setAddBucket(true)} className="general-button" style={{ width: "200px" }}>
          Add a Bucket
        </div>
      </div>
    </>
  );
};

export default BucketsEmpty;
