import { IonSpinner } from "@ionic/react";
import { Fragment, useEffect, useState, useCallback, memo } from "react";
import Chart from "react-apexcharts";
import { fNK, prepareChartData } from "../../functions/baseFunctions";
import axios from "axios"; // Ensure axios is imported

// const NAVChart = ({ data, color, type, deposits }) => {
const NAVChart = memo(({ data, color, type, deposits }) => {
  const [dates, setDates] = useState([]);
  const [duration, setDuration] = useState(22);
  const [plotter, setPlotter] = useState("NAV");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [periodPerformancePercent, setPeriodPerformancePercent] = useState();
  const [periodPerformanceAmount, setPeriodPerformanceAmount] = useState();

  const processData = useCallback(
    async (sizer, cancelToken) => {
      setLoading(true);
      const isYTD = sizer === "ytd";
      setDuration(sizer);
      try {
        const prerepedData = await prepareChartData(data, sizer, deposits, isYTD, cancelToken);
        setDates(prerepedData.dates);

        const lastAmount = prerepedData.amounts[prerepedData.amounts.length - 1];
        const firstAmount = prerepedData.amounts[0];
        setPeriodPerformancePercent(((lastAmount / firstAmount) * 100 - 100).toFixed(2));
        setPeriodPerformanceAmount(Math.floor(lastAmount - firstAmount));

        const updatedChartData = {
          name: plotter,
          data: plotter === "NAV" ? prerepedData.amounts : prerepedData.rois,
        };
        setChartData([updatedChartData]);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          // handle error
        }
      } finally {
        setLoading(false);
      }
    },
    [data, deposits, plotter]
  );

  const processPlotter = (plotterNumber) => {
    setPlotter(plotterNumber);
    processData(duration);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    processData(duration, source.token);
    return () => {
      source.cancel("Component unmounted");
    };
  }, [processData, duration, plotter]);

  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: color,
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
      padding: {
        top: -2,
        bottom: -8,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (plotter === "NAV" ? `$${fNK(value)}` : `${value.toFixed(2)}%`),
        style: {
          fontSize: "10px",
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      categories: dates,
      tickAmount: 16,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  return (
    <Fragment>
      <p />
      <div className="nav-plotter">
        <div onClick={() => processPlotter("NAV")} className={plotter === "NAV" ? "nav-plotter-item-active" : "nav-plotter-item"}>
          NAV
        </div>
        {type !== "crypto" && (
          <div onClick={() => processPlotter("ROI")} className={plotter === "ROI" ? "nav-plotter-item-active" : "nav-plotter-item"}>
            ROI
          </div>
        )}
      </div>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IonSpinner name="dots" />
        </div>
      )}

      <NavDurations type={type || "stock"} processData={processData} duration={duration} />

      <div className="nav-chart-period-performance" style={{ color: periodPerformanceAmount > 0 ? "green" : "red" }}>
        <span style={{ padding: "4px" }}>${fNK(periodPerformanceAmount)}</span> <span style={{ padding: "4px", color: "black" }}>|</span>
        <span style={{ padding: "4px" }}>
          <b>{periodPerformancePercent}%</b>
        </span>
      </div>
      <div style={{ justifyContent: "center", margin: "0px" }}>
        <div id="chart">
          <Chart options={options} series={chartData} type="line" height={300} />
        </div>
      </div>
    </Fragment>
  );
});

export default NAVChart;

const NavDurations = ({ type, processData, duration }) => {
  const durations =
    type === "stock"
      ? [
          { name: "YTD", value: "ytd" },
          { name: "30 Days", value: 22 },
          { name: "60 Days", value: 44 },
          { name: "3 Months", value: 66 },
          { name: "6 Months", value: 145 },
          { name: "1 Year", value: 320 },
          { name: "MAX", value: "max" },
        ]
      : [
          { name: "YTD", value: "ytd" },
          { name: "30 Days", value: 30 },
          { name: "60 Days", value: 60 },
          { name: "3 Months", value: 90 },
          { name: "6 Months", value: 180 },
          { name: "1 Year", value: 365 },
          { name: "MAX", value: 1000 },
        ];

  return (
    <div className="nav-durations">
      {durations.map((item, index) => (
        <div key={index} onClick={() => processData(item.value)} style={duration === item.value ? { color: "black", fontWeight: 800 } : {}}>
          {item.name}
        </div>
      ))}
    </div>
  );
};
