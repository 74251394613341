import { useState } from "react";
import { IonCol, IonIcon, IonRow } from "@ionic/react";
import { chevronDownCircle } from "ionicons/icons";
import { useSelector } from "react-redux";

import Dca from "./Dca";
import { fN, fNK } from "../../functions/baseFunctions";

const StockTradesStats = ({ data, privacyMode }) => {
  const userData = useSelector((state) => state.data);

  const [showWTDTrades, setShowWTDTrades] = useState(false);
  return data ? (
    <IonRow>
      <IonCol size="12">
        <div className="stock-tradex-stats">
          <b>TRADES / DCA: </b> WTD <b>${privacyMode ? "***" : data.tradesAmountWTD}</b>| MTD{" "}
          <span style={Number(data.tradesAmountMTD) > Number(data.stockTradesMonthlyBudget) ? { color: "red" } : null}>
            <b>${privacyMode ? "***" : fNK(data.tradesAmountMTD)}</b>
          </span>{" "}
          | YTD <b>${privacyMode ? "***" : data.tradesAmountYTD}</b>{" "}
          <span style={{ float: "right" }}>
            <IonIcon icon={chevronDownCircle} onClick={() => setShowWTDTrades(!showWTDTrades)} style={{ fontSize: "16px", color: "#34495e" }} />
          </span>
          {showWTDTrades && (
            <>
              <p />
              <IonCol size="12">
                <IonRow>
                  <div style={{ justifyContent: "center", display: "flex", backgroundColor: "white", borderRadius: "8px", width: "100%" }}>
                    <Dca stockDCA={userData.stockDCA} />
                  </div>
                </IonRow>
              </IonCol>
              <br />
              <span style={{ backgroundColor: "darkgrey", padding: "2px", paddingLeft: "4px", paddingRight: "4px", borderRadius: "4px", color: "white" }}>
                Monthly Budget: <b>{fNK(data.stockTradesMonthlyBudget)}</b>
              </span>
              <p />
              Trades / Week-to-date (<b>{data.tradesNumberWTD})</b>
              <p />
              <div style={{ backgroundColor: "white", borderRadius: "8px", width: "100%" }}>
                <table className="stock-main-table">
                  <thead>
                    <tr className="stock-trades-header">
                      <th>Stock</th>
                      <th>Trade</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Cost</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.tradesWTD.map((item, index) => (
                      <tr className="stock-trades-item" key={index}>
                        <td style={{ textAlign: "left" }}>
                          <b>{item.symbol && item.symbol.slice(0, 6)}</b>
                        </td>
                        <td>{item.trade}</td>
                        <td style={{ textAlign: "left" }}>${item.price}</td>
                        <td>{<b>{item.quantity}</b>}</td>
                        <td style={{ textAlign: "left" }}>${fN(Math.floor(item.cost))}</td>

                        <td style={{ textAlign: "left" }}>{item.tradeDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p />
            </>
          )}
        </div>
      </IonCol>
    </IonRow>
  ) : (
    <IonRow>
      <IonCol size="12"></IonCol>
    </IonRow>
  );
};

export default StockTradesStats;
