const CryptoTopCoins: React.FC<any> = ({ cryptoTopCrypto }): JSX.Element => {
  return (
    <>
      {cryptoTopCrypto.length > 0 &&
        cryptoTopCrypto
          .sort((a: any, b: any) => (a.RAW.USD.MKTCAP < b.RAW.USD.MKTCAP ? 1 : -1))
          .map((item: any, index: any) => (
            <div className="crypto-top-coin-item" key={index}>
              <span style={{ fontWeight: 600, marginRight: "2px", fontSize: "16px" }}>{index + 1} </span>
              <b>{item.CoinInfo.FullName}</b> | {item.DISPLAY.USD.OPEN24HOUR}
              <span className="top-crypto-markcap">{item.DISPLAY.USD.MKTCAP}</span>
            </div>
          ))}
    </>
  );
};

export default CryptoTopCoins;
