// import * as firebase from "firebase";
import firebase from "firebase/app";
require("firebase/auth");
require("firebase/database");

const config = {
  apiKey: "AIzaSyB8ODRCSfOcq4HEoD4XoDaBeuZDu3yDjDQ",
  authDomain: "personalinvestment-e59c3.firebaseapp.com",
  databaseURL: "https://personalinvestment-e59c3-default-rtdb.firebaseio.com",
  projectId: "personalinvestment-e59c3",
  storageBucket: "personalinvestment-e59c3.appspot.com",
  messagingSenderId: "790428344462",
  appId: "1:790428344462:web:ed248c3ef51e5c2d1329df",
  measurementId: "G-EH3MYL2F2X",
};

firebase.initializeApp(config);

export const loginUser = async (username: string, password: string) => {
  try {
    const res = await firebase.auth().signInWithEmailAndPassword(username, password);
    return { status: "success", data: res.user };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const logoutUser = async () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      return { status: "success" };
    })
    .catch((e) => {
      console.log("Error signing out: ", e);
    });
};

export const updateRecord = async (userId: any, path: any, data: any) => {
  try {
    const dataResponse = await firebase
      .database()
      .ref(userId)
      .child(path)
      .update(data)
      .then((res) => res);
    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const addRecord = async (userId: any, path: any, data: any) => {
  try {
    const dataResponse = await firebase
      .database()
      .ref(userId)
      .child(path)
      .set(data)
      .then((res) => res);
    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const pushRecord = async (userId: any, path: any, data: any) => {
  try {
    const dataResponse = await firebase
      .database()
      .ref(userId)
      .child(path)
      .push(data)
      .then((res) => res);
    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const getChildData = async (userId: any, path: any) => {
  try {
    const dataResponse = await firebase
      .database()
      .ref(userId)
      .child(path)
      .once("value")
      .then((snapshot) => snapshot.val());
    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const editChildData = async (userId: any, path: any, data: any) => {
  try {
    const dataResponse = await firebase
      .database()
      .ref(userId)
      .child(path)
      .set(data)
      .then((res) => res);

    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const getData = async (userId: any) => {
  try {
    const dataResponse = await firebase
      .database()
      .ref(userId.uid)
      .once("value")
      .then((snapshot) => snapshot.val());
    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log(e);
    return { status: "error", message: e.message };
  }
};

export const getRemoteConfig = async () => {
  try {
    const dataResponse = await firebase
      .database()
      .ref("remoteConfigz")
      .once("value")
      .then((snapshot) => snapshot.val());
    return { status: "success", data: dataResponse };
  } catch (e: any) {
    console.log("error getting the remote config", e);
    return { status: "error", message: e.message };
  }
};

export const registerUser = async (username: string, password: string) => {
  try {
    const res = await firebase.auth().createUserWithEmailAndPassword(username, password);
    console.log(res.user);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        unsubscribe();
      }
    });
  });
};
