import { Fragment } from "react";

const StockSettings = ({ stockSettings, setStockSettings }) => {
  const panelsArray = [
    { name: "Cash Report", value: "cashReport" },
    // { name: "Top Stats", value: "topStats" },
    { name: "Indices", value: "indices" },
    { name: "Watch List", value: "watchList" },
    { name: "Top G&L", value: "topGainersLosers" },
    { name: "Earn", value: "earnPanel" },
    { name: "Div.", value: "divPanel" },
    { name: "Trades", value: "trades" },
  ];

  const settingsArray = [
    { name: "Last", value: "last" },
    { name: "Rate", value: "averageAnalystRating" },
    { name: "Avg.P", value: "avgPrice" },
    { name: "CHN.$", value: "change" },
    { name: "CHN.%", value: "changePercent" },
    { name: "Position", value: "position" },
    { name: "PnL", value: "pnl" },
    { name: "M.Value", value: "marketValue" },
    { name: "Unrlz", value: "unrealized_pnl" },
    { name: "Unrlz.%", value: "unrealized_percent" },
    { name: "NAV%", value: "percentOfNAV" },
    { name: "Div%", value: "dividendPercent" },
    { name: "Div/Y", value: "dividendTotal" },
    { name: "Div.D", value: "dividendDate" },
    { name: "FPE", value: "forwardPE" },
  ];

  const handleOption = (option) => {
    setStockSettings({ ...stockSettings, [option]: !stockSettings[option] });
    localStorage.setItem(`stockSettings.${option}`, !stockSettings[option]);
  };
  return (
    <Fragment>
      <p />
      <div style={{ textAlign: "center" }}>
        {panelsArray.map((item, index) => (
          <span key={index} className={stockSettings[item.value] ? "stock-options-tag-active" : "stock-options-tag"} onClick={() => handleOption(item.value)}>
            {item.name}
          </span>
        ))}
      </div>
      <p />
      <div style={{ textAlign: "center" }}>
        {settingsArray.map((item, index) => (
          <span key={index} className={stockSettings[item.value] ? "stock-options-tag-active" : "stock-options-tag"} onClick={() => handleOption(item.value)}>
            {item.name}
          </span>
        ))}
      </div>

      <p />
    </Fragment>
  );
};

export default StockSettings;
