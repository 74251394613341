import { Fragment, useEffect, useState } from "react";
import { IonSpinner, IonIcon } from "@ionic/react";
import axios from "axios";
import Chart from "react-apexcharts";
import { closeCircleOutline } from "ionicons/icons";

const StockSummaryChart = ({ symbol, chartName, lastPrice, token, remoteConfig, setShowChart }) => {
  const [gainsData, setGainsData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [range, setRange] = useState("12mo");
  const [periodPerformancePercent, setPeriodPerformancePercent] = useState();
  const [periodPerformanceAmount, setPeriodPerformanceAmount] = useState();

  const dateFormater = (data) => {
    // return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-us", { day: "numeric", month: "short" }));
    if (range === "3mo" || range === "6mo" || range === "ytd") {
      return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-uk", { day: "numeric", month: "short" }));
    } else if (range === "12mo" || range === "2y" || range === "5y" || range === "max") {
      return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-uk", { day: "numeric", month: "short", year: "2-digit" }));
    } else {
      return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-uk", { month: "narrow" }));
    }
  };

  const dataFN = () => {
    return [
      {
        name: "Gain",
        data: gainsData.map((item) => Number(item != null && item.toFixed(2))),
      },
    ];
  };
  let options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: periodPerformanceAmount > 0 ? ["green"] : ["red"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0,
      },
    },
    title: {
      text: "",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.3,
      },
      padding: {
        top: -20,
        bottom: -15,
      },
    },
    xaxis: {
      categories: dateFormater(datesData),
      tickAmount: 16,
    },
  };

  const getChart = async (interval) => {
    const theChart = await axios(`${remoteConfig.stockURL}/chart/${symbol}?appToken=${token}&range=${range || "12mo"}&interval=${interval || "1mo"}`);
    setGainsData(theChart.data.chart.result[0].indicators.quote[0].close);
    setDatesData(theChart.data.chart.result[0].timestamp);
    setPeriodPerformancePercent(((lastPrice / theChart.data.chart.result[0].indicators.quote[0].close[0]) * 100 - 100).toFixed(2));
    setPeriodPerformanceAmount((lastPrice - theChart.data.chart.result[0].indicators.quote[0].close[0]).toFixed(2));
  };

  useEffect(() => {
    if (range === "3mo" || range === "6mo" || range === "ytd" || range === "12mo") {
      getChart("1d");
    } else if (range === "2y" || range === "5y" || range === "max") {
      getChart("5d");
    } else {
      getChart();
    }
  }, [range, symbol]);

  return gainsData.length > 0 ? (
    <Fragment>
      <div style={{ justifyContent: "center", display: "flex", fontSize: "20px", fontWeight: 500, letterSpacing: "2px" }}>{chartName.toUpperCase()}</div>
      <div style={{ textAlign: "center", fontSize: "12px", marginBottom: "6px" }}>
        {/* THE STOCK PERFORMANCE $ & % */}
        <span className="stock-chart-period-performance" style={periodPerformanceAmount > 0 ? { color: "green" } : { color: "red" }}>
          ${periodPerformanceAmount} | <b>{periodPerformancePercent}%</b>
        </span>
        {/* THE GRAPH DURATIONS */}
        <span style={range === "ytd" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("ytd")}>
          YTD{" | "}
        </span>
        <span style={range === "3mo" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("3mo")}>
          3M{" | "}
        </span>
        <span style={range === "6mo" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("6mo")}>
          6M{" | "}
        </span>
        <span style={range === "12mo" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("12mo")}>
          1Y{" | "}
        </span>
        <span style={range === "2y" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("2y")}>
          2Y{" | "}
        </span>
        <span style={range === "5y" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("5y")}>
          5Y{" | "}
        </span>
        <span style={range === "max" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("max")}>
          MAX
        </span>
        <IonIcon icon={closeCircleOutline} style={{ marginBottom: "-4px", marginLeft: "4px", fontSize: "18px", color: "red" }} onClick={() => setShowChart(false)} />
      </div>

      <div id="chart" className="stock-symbol-chart-wrap">
        <Chart options={options} series={dataFN()} type="area" height={250} />
      </div>
    </Fragment>
  ) : (
    <div style={{ textAlign: "center" }}>
      <IonSpinner />
    </div>
  );
};

export default StockSummaryChart;
