import { IonIcon } from "@ionic/react";
import { cash, business } from "ionicons/icons";

import { fN, fNK } from "../../functions/baseFunctions";

const InvestmentsStatus: React.FC<any> = ({ privacyMode, total, cashInvestments, assetsInvestments, stocksNetLiq, cryptoTotal, realEstate, assetsStats }): JSX.Element => {
  let netWorth = total + stocksNetLiq + cryptoTotal;

  return (
    <div className="icons-top-wrapper">
      <div className="num-coins">
        <IonIcon icon={cash} size="small" style={{ marginRight: "4px", color: "grey" }} />
        Cash
        <br />
        <div className="item-value" style={{ fontWeight: 500 }}>
          ${privacyMode ? "****" : cashInvestments && fN(cashInvestments)}
        </div>
        <div style={{ marginTop: "8px" }}>Interest/Y</div>
        <div className="item-value" style={{ fontWeight: 300 }}>
          ${fNK(assetsStats.interest)}
        </div>
      </div>
      <div className="num-coins">
        <span style={{ fontSize: "14px", letterSpacing: "1px" }}> Net Worth</span>
        <br />
        <div style={{ fontSize: "22px", fontWeight: 600, letterSpacing: "-0.5px" }}>{privacyMode ? "******" : "$" + fN(Math.floor(netWorth))}</div>
        <div style={{ marginTop: "8px" }}>Total Income/Y</div>
        <div className="item-value" style={{ fontWeight: 300 }}>
          <b>${fNK(assetsStats.totalIncome)}</b>
        </div>
      </div>
      <div className="num-last">
        <IonIcon icon={business} size="small" style={{ marginRight: "4px", color: "grey" }} />
        Assets
        <br />
        <span className="item-value" style={{ fontWeight: 500 }}>
          ${privacyMode ? "****" : realEstate && fN(realEstate)}
        </span>
        <div style={{ marginTop: "8px" }}>Cash Flow/Y</div>
        <div className="item-value" style={{ fontWeight: 300 }}>
          ${fNK(assetsStats.cashFlow)}
        </div>
      </div>
    </div>
  );
};

export default InvestmentsStatus;
