import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { pushRecord, getChildData, editChildData } from "../../functions/funcFirebase";
import { IonHeader, IonButtons, IonBackButton, IonTitle, IonCol, IonContent, IonIcon, useIonAlert, IonItem, IonLabel, IonPage, IonRow, IonToolbar } from "@ionic/react";

import { trashOutline, createOutline } from "ionicons/icons";

const StockDCAManage = () => {
  // SET THE ITEM NAME FOR FIREBASE OPS
  const itemName = "stockDCA/stocks";

  const userID = useSelector((state) => state.user.uid);
  //   const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [formType, setFormType] = useState("");
  const [form, setForm] = useState({});
  const [activeID, setActiveID] = useState("");
  const [present] = useIonAlert();

  const getItems = async () => {
    const resData = await getChildData(userID, itemName);
    setItemsData(resData && resData.data && Object.entries(resData.data));
  };

  const addItem = () => {
    pushRecord(userID, itemName, form);
    setForm({});
    setFormType("");
    getItems();
  };

  const populateItem = async (id) => {
    setForm({});
    setActiveID(id);
    setFormType("edit");
    const resData = await getChildData(userID, `/${itemName}/${id}`);
    setForm(resData.data);
  };

  const editItem = async () => {
    setFormType("edit");
    editChildData(userID, `${itemName}/${activeID}`, form);
    setFormType("");
    setForm({});
    getItems();
  };

  const deleteItem = async (id) => {
    editChildData(userID, `${itemName}/${id}`, null);
    getItems();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/stocks" />
            </IonButtons>
            <IonTitle>Dollar Cost Average</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonRow>
          <IonCol size="12">
            <div>
              <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
                <div onClick={() => setFormType("add")} className="general-button" style={{ width: "200px" }}>
                  Add Stock
                </div>
              </div>
              <p />
              {itemsData && itemsData.length > 0 ? (
                itemsData.map((item) => {
                  let theKey = item[0];
                  let theData = item[1];
                  return (
                    <IonItem key={theKey}>
                      <IonLabel>
                        <strong>{theData.symbol && theData.symbol.toUpperCase()}</strong>

                        <p>{theData.notes}</p>
                      </IonLabel>
                      <span style={{ fontSize: "18px" }}>
                        <b>{theData.count}</b>
                      </span>

                      <IonIcon
                        icon={createOutline}
                        onClick={() => populateItem(theKey)}
                        style={{ paddingRight: "4px", paddingLeft: "16px", color: "darkblue", fontSize: "18px" }}
                      />

                      <IonIcon
                        icon={trashOutline}
                        onClick={() =>
                          present({
                            cssClass: "my-css",
                            header: "Delete",
                            message: "Are you sure you want to delete this item?",
                            buttons: ["Cancel", { text: "Delete", handler: (d) => deleteItem(theKey) }],
                          })
                        }
                        style={{ paddingLeft: "6px", color: "darkred", fontSize: "18px" }}
                      />
                    </IonItem>
                  );
                })
              ) : (
                // ####################### NO Stocks ADDED YET #######################
                <>
                  <br />
                  <div style={{ textAlign: "center", fontSize: "16px", fontWeight: 500, color: "darkgray" }}>No Stocks Added Yet</div>
                  <br />
                  <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
                    <div onClick={() => setFormType("add")} className="general-button" style={{ width: "200px" }}>
                      Add Stock
                    </div>
                  </div>
                </>
              )}
            </div>
          </IonCol>
        </IonRow>

        {/* // ####################### ADD/EDIT FORM ####################### */}

        {(formType === "add" || formType === "edit") && (
          <div className="stock-account-form-edit">
            <table className="stock-account-table">
              <tbody>
                <tr>
                  <td style={{ width: "40%" }}>Order:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="order" value={form["order"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Symbol:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="symbol" value={form["symbol"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Budget/week:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="budget" value={form["budget"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Bought:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="bought" value={form["bought"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Notes</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="notes" value={form["notes"] || ""} onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>

            <p />

            {formType === "add" && (
              <div className="bottons-divider">
                <div onClick={() => addItem()} className="general-button-small">
                  Add
                </div>
                <div onClick={() => setFormType("")} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
            {formType === "edit" && (
              <div className="bottons-divider">
                <div onClick={() => editItem()} className="general-button-small">
                  Save
                </div>
                <div onClick={() => setFormType("")} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StockDCAManage;
