import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";

import { getChildData } from "../functions/funcFirebase";
import BucketsEmpty from "../components/buckets/BucketsEmpty";
import BucketsList from "../components/buckets/BucketsList";
import BucketsHeader from "../components/buckets/BucketsHeader";
import BucketsAddEdit from "../components/buckets/BucketsAddEdit";
import BucketDetail from "../components/buckets/BucketDetail";

const Buckets = () => {
  const itemName = "buckets";

  const userID = useSelector((state) => state.user.uid);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [bucketsData, setBucketsData] = useState([]);
  const [bucketData, setBucketData] = useState({});
  const [bucketKey, setBucketKey] = useState("");
  const [action, setAction] = useState("add");
  const [addBucket, setAddBucket] = useState(false);
  const [showBucket, setShowBucket] = useState();

  const getItems = async () => {
    try {
      const resData = await getChildData(userID, itemName);
      // setBucketsData(resData && resData.data && Object.entries(resData.data));
      setBucketsData(Object.entries(resData?.data || {}));
    } catch (error) {
      console.error("Error getting items: ", error);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <BucketsHeader setPrivacyMode={setPrivacyMode} privacyMode={privacyMode} setAddBucket={setAddBucket} getItems={getItems} />

        {addBucket && (
          <BucketsAddEdit userID={userID} setAddBucket={setAddBucket} addBucket={addBucket} getItems={getItems} bucketData={bucketData} action={action} bucketKey={bucketKey} />
        )}

        {bucketsData && bucketsData.length > 0 ? <BucketsList bucketsData={bucketsData} setShowBucket={setShowBucket} /> : <BucketsEmpty setAddBucket={setAddBucket} />}

        {showBucket && (
          <BucketDetail
            userID={userID}
            itemName={itemName}
            getItems={getItems}
            bucketsData={bucketsData}
            setShowBucket={setShowBucket}
            showBucket={showBucket}
            setAddBucket={setAddBucket}
            setBucketData={setBucketData}
            setAction={setAction}
            setBucketKey={setBucketKey}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Buckets;
