import React from "react";
import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RefresherEventDetail } from "@ionic/core";
import { IonCol, IonRow, IonContent, IonPage, IonIcon, IonRefresher, IonRefresherContent, IonToolbar } from "@ionic/react";
import { refreshOutline, fingerPrintOutline, ellipsisVerticalOutline, pieChart } from "ionicons/icons";

import Dca from "../components/stocks/Dca";
import FireStatus from "../components/fire/FireStatus";
import StocksStats from "../components/stocks/StocksStats";
import CryptoFavHome from "../components/crypto/CryptoFavHome";
import CryptoStatsHome from "../components/crypto/CryptoStatsHome";
import NetWorthChart from "../components/investments/NetWorthChart";
import InvestmentsStatus from "../components/investments/InvestmentsStatus";
import BucketsList from "../components/buckets/BucketsList";

import { setDataState, setHomeCryptoDataState, setHomeStockDataState } from "../redux/actions";
import { getCryptoInfo } from "../functions/funcCrypto";
import { getStockInfo, getDefaultStockAccount } from "../functions/stockFunctions";
import { getChildData, getData } from "../functions/funcFirebase";
import { getAssetsStats } from "../functions/assetsFunctions";
import "./Home.css";

let today = new Date();

const Home: React.FC = () => {
  const userID = useSelector((state: any) => state.user.uid);
  const userData = useSelector((state: any) => state.data);
  const remoteConfig = useSelector((state: any) => state.remoteConfig);
  const dispatch = useDispatch();

  const [fireData, setFireData] = useState(userData.fire);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [activeAccountID, setActiveAccountID]: any = useState("");
  const [stockNAV, setStockNAV] = useState();
  const [cryptoInfo, setCryptoInfo]: any = useState();
  const [bucketsData, setBucketsData] = useState([]);
  const [assetsStats, setAssetsStats]: any = useState({ total: 0, realEstate: 0, cash: 0, other: 0 });
  const [stockInfoStats, setStockInfoStats]: any = useState({ total: 0, deposits: 0, costBasis: 0, profits: 0, numberOfStocks: 0, dailyPNL: 0, stocksROI: 0 });
  const [cryptoInfoStats, setCryptoInfoStats]: any = useState({
    cryptoTotal: 0,
    cryptoInvested: 0,
    cryptoProfits: 0,
    numberOfStocks: 0,
    cryptoDailyPNL: 0,
    numberOfBrokers: 0,
    numberOfCoins: 0,
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    refreshData();
    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  };

  const refreshData = async () => {
    const dataRes = await getData({ uid: userID });
    if (dataRes.status === "success") {
      dispatch(setDataState(dataRes.data));
    } else {
      console.log("Firebase Refresh Error Error");
    }
    setFireData(userData.fire);
    setStockInfoStats({ total: 0, deposits: 0, costBasis: 0, profits: 0, numberOfStocks: 0, dailyPNL: 0, stocksROI: 0 });
    getCrypto();
    getStock();
    getAssets();
  };

  const getCrypto = async () => {
    const cryptoRawData = await getChildData(userID, "/crypto");
    const cryptoInfo = await getCryptoInfo(userID, remoteConfig, cryptoRawData.data);
    setCryptoInfo(cryptoInfo.crypto);
    setCryptoInfoStats(cryptoInfo.stats);
    dispatch(setHomeCryptoDataState(cryptoInfo));
  };

  const getBuckets = async () => {
    try {
      const resData = await getChildData(userID, "buckets");
      setBucketsData(resData && resData.data && Object.entries(resData.data));
    } catch (error) {
      console.error("Error getting items: ", error);
    }
  };

  const getStock = async () => {
    const defaultStockAccount: any = getDefaultStockAccount(userData.accounts);
    const stockInfo = await getStockInfo(
      userID,
      remoteConfig,
      userData[`account-data${defaultStockAccount.id}`].positions,
      "IBKR",
      defaultStockAccount.data.deposits,
      userData[`account-data${defaultStockAccount.id}`].cash.endingCash
    );
    setStockInfoStats(stockInfo.stats);
    dispatch(setHomeStockDataState(stockInfo));
  };

  const getAssets = () => {
    setAssetsStats(getAssetsStats(userData.assets));
  };

  useEffect(() => {
    getCrypto();
    getStock();
    getAssets();
    getBuckets();
    const defaultStockAccount: any = getDefaultStockAccount(userData.accounts);
    setActiveAccountID(defaultStockAccount.id);
    setStockNAV(userData[`account-data${defaultStockAccount.id}`].NAV);
  }, []);

  return (
    <IonPage>
      {/* <IonToolbar mode="ios"></IonToolbar> */}

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="the-top-container">
          <div className="home-header">
            <IonRow>
              <IonCol size="12">
                <div className="top-title">
                  <IonIcon icon={pieChart} style={{ marginBottom: -3, marginRight: 2 }} />
                  <span style={{ fontWeight: 500, letterSpacing: "0.5px", fontSize: "30px" }}>NAV</span>
                  <span style={{ fontWeight: 200, letterSpacing: "0.5px", fontSize: "30px" }}>SCAPE</span>

                  <div style={{ marginTop: "1px", marginRight: "0px", float: "right" }}>
                    {privacyMode && <span className="crypto-privacy-mode">PRIVACY ON</span>}
                    <IonIcon icon={fingerPrintOutline} onClick={() => setPrivacyMode(!privacyMode)} style={{ paddingLeft: "12px" }} />
                    <IonIcon icon={refreshOutline} onClick={() => refreshData()} style={{ paddingLeft: "12px" }} />
                    <IonIcon icon={ellipsisVerticalOutline} style={{ paddingLeft: "6px", paddingRight: "0px" }} />
                    <p />
                  </div>
                </div>
                <IonCol size="12">
                  <span className="home-app-version">
                    App: <b>v0.9-beta</b> <span style={{ margin: "8px" }}>|</span>
                    License: <b>Pro</b>
                  </span>
                </IonCol>
              </IonCol>
            </IonRow>
          </div>
          {userID ? (
            <Fragment>
              <InvestmentsStatus
                privacyMode={privacyMode}
                total={assetsStats.total}
                cashInvestments={assetsStats.cash}
                assetsInvestments={assetsStats.total}
                stocksNetLiq={stockInfoStats.total}
                cryptoTotal={cryptoInfoStats.cryptoTotal}
                realEstate={assetsStats.realEstate}
                assetsStats={assetsStats}
              />

              <NetWorthChart
                privacyMode={privacyMode}
                cash={assetsStats.cash}
                assets={assetsStats.realEstate + assetsStats.other}
                stocks={stockInfoStats.total}
                crypto={cryptoInfoStats.cryptoTotal}
              />

              <div className="section-header">Stocks</div>
              <StocksStats
                privacyMode={privacyMode}
                stocksNetLiq={stockInfoStats.total}
                stocksROI={stockInfoStats.stocksROI}
                stockProfits={stockInfoStats.total - stockInfoStats.deposits}
                stocksInvestedCash={stockInfoStats.deposits}
                stockPNL={stockInfoStats.dailyPNL}
              />

              <div className="section-header">Crypto</div>
              <CryptoStatsHome homeState={true} privacyMode={privacyMode} cryptoInfoStats={cryptoInfoStats} />
              <CryptoFavHome cryptoInfo={cryptoInfo} />

              <div className="section-header">Fire</div>
              <FireStatus
                privacyMode={privacyMode}
                stocksNetLiq={stockInfoStats.total}
                cryptoInvestments={cryptoInfoStats.cryptoTotal}
                data={fireData}
                endingCash={stockInfoStats.endingCash}
                includeStockCashAsInvestment={userData.settings.fire.includeStockCashAsInvestment}
                assets={assetsStats.investment && assetsStats.investment}
              />

              <div className="section-header">Buckets</div>
              {bucketsData && bucketsData.length > 0 && <BucketsList bucketsData={bucketsData} setShowBucket={null} />}

              <div className="section-header">
                <span className="dca-interval">{userData.stockDCA.interval}</span> DCA{" "}
                <span className="dca-day">Day: {userData.stockDCA.interval.toLowerCase() === "weekly" ? today.getDay() + "/5" : today.getDate() + "/30"}</span>
              </div>

              <div style={{ justifyContent: "center", display: "flex" }}>
                <Dca stockDCA={userData.stockDCA} />
              </div>
            </Fragment>
          ) : (
            <div style={{ textAlign: "center" }}>
              <p />
              <span style={{ color: "lightgrey", fontSize: "32px", fontWeight: 800, textAlign: "center" }}>Please Login</span>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
