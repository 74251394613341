import { Fragment } from "react";

const StockWatchList = ({ liveWatchListData, setFilteredStock }) => {
  return (
    <Fragment>
      <div className="stock-watch-list-title">Watch List</div>

      <div className="stock-watch-list-wrapper">
        <div style={{ width: "max-content", marginTop: "0px", marginBottom: "4px" }}>
          <div>
            {liveWatchListData &&
              liveWatchListData.length > 0 &&
              liveWatchListData
                .sort((a, b) => (a.dropPercent > b.dropPercent ? 1 : -1))
                .map((item, index) => (
                  <div
                    key={index}
                    className="stock-watch-list-item"
                    onClick={() => setFilteredStock(item.symbol)}
                    style={{ borderBottomColor: item.alertBuy ? "darkgrey" : "#f3f3f3" }}
                  >
                    <span style={{ fontSize: "12px", color: item.color }}>
                      <b>{item.symbol && item.symbol.toUpperCase()}</b>
                      {/* {item.priority && <IonIcon icon={ribbon} style={{ margin: "0px", marginLeft: "4px", color: "darkorange", fontSize: "13px" }} />} */}
                    </span>
                    <br />
                    {item.last.toFixed(2)} / <span style={{ fontSize: "12px", color: "grey" }}>{item.watchPrice}</span>
                    {/* <br />
                    {item.alertType === "SELL" && <span style={{ fontSize: "11px", color: "grey" }}>{item.dropPercent.toFixed(1)}%</span>}
                    {item.alertType === "BUY" && <span style={{ fontSize: "11px", color: "grey" }}>{item.upPercent.toFixed(1)}%</span>} */}
                    <div>
                      {item.priority && <span className="stock-watch-list-priority">PRIORITY</span>}
                      {item.type === "SELL" && (
                        <span className="stock-watch-list-sell" style={{ color: item.color }}>
                          SELL
                        </span>
                      )}{" "}
                      {item.type === "BUY" && (
                        <span className="stock-watch-list-buy" style={{ color: item.color }}>
                          BUY
                        </span>
                      )}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StockWatchList;
