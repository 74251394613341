import { useEffect, useState } from "react";
import { fN, fNKL } from "../../functions/baseFunctions";
import { IonIcon, IonPopover } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";

const StockHeaderStats = ({ privacyMode, activeStockAccount, totalMktValue, accountData, manualStockAccountInfo, stockROI }) => {
  const [accountValue, setAccountValue] = useState(0);
  const [accountManualValue, setAccountManualValue] = useState(0);

  // const [ratePopover, setRatePopover] = useState({ showPopover: false, event: undefined });
  // const [depositesPopover, setDepositesPopover] = useState({ showPopover: false, event: undefined });

  useEffect(() => {
    if (activeStockAccount.type === "IBKR") {
      setAccountValue(Number(totalMktValue) + Number(accountData.cash.endingCash));
    } else {
      // setAccountManualValue(Number(activeStockAccount.cashBalance));
      setAccountManualValue(0);
      manualStockAccountInfo &&
        manualStockAccountInfo.length > 0 &&
        manualStockAccountInfo.map((item) => {
          setAccountManualValue((accountManualValue) => Number(accountManualValue) + Number(item.position) * Number(item.last));
        });
    }
  }, [activeStockAccount, accountData, totalMktValue, manualStockAccountInfo]);

  return (
    <div className="stock-top-stats">
      <div className="stocks-top-invested">
        <div style={{ fontSize: "12px", paddingRight: "6px", fontWeight: 250 }}>
          Deposits
          {/* <IonIcon
            icon={informationCircleOutline}
            style={{ paddingLeft: "3px", paddingRight: "0px", fontSize: "12px" }}
            // onClick={(e) => {
            //   e.persist();
            //   setDepositesPopover({ showPopover: true, event: e });
            // }}
          /> */}
        </div>
        <div style={{ fontSize: "18px" }}>
          <b>${privacyMode ? "***" : activeStockAccount.deposits && fNKL(activeStockAccount.deposits)}</b>
        </div>
      </div>

      {/* <IonPopover event={depositesPopover.event} isOpen={depositesPopover.showPopover} onDidDismiss={() => setDepositesPopover({ showPopover: false, event: undefined })}>
        <div style={{ margin: "4px", padding: "3px" }}>Deposites minus withdrawals since inception. You can set this number from the Settings section.</div>
      </IonPopover>

      <IonPopover event={ratePopover.event} isOpen={ratePopover.showPopover} onDidDismiss={() => setRatePopover({ showPopover: false, event: undefined })}>
        <div style={{ margin: "4px", padding: "3px" }}>Profits and Simple Rate of Return (SSR) since inception</div>
      </IonPopover> */}

      <div className="stock-top-total">
        <span style={{ fontSize: "21px", fontWeight: 400, marginTop: "-10px" }}>$</span>
        {privacyMode ? "***" : activeStockAccount.type === "IBKR" ? fNKL(Math.floor(accountValue)) : fNKL(Math.floor(accountManualValue + Number(activeStockAccount.cashBalance)))}
      </div>

      <div className="stocks-top-profits">
        <div style={{ float: "right" }}>
          <div style={{ fontSize: "12px", paddingRight: "6px", fontWeight: 250, textAlign: "right" }}>
            Profits / SRR
            {/* <IonIcon
              icon={informationCircleOutline}
              style={{ paddingLeft: "3px", paddingRight: "0px", fontSize: "16px" }}
              // onClick={(e) => {
              //   e.persist();
              //   setRatePopover({ showPopover: true, event: e });
              // }}
            /> */}
          </div>
          <div style={{ fontSize: "18px" }}>
            {privacyMode ? (
              "***"
            ) : activeStockAccount.type === "IBKR" ? (
              <>
                <b>${fNKL(Math.floor(accountValue) - activeStockAccount.deposits)}</b> <span style={{ fontSize: "10px", fontWeight: 500 }}>({stockROI}%)</span>
              </>
            ) : (
              <>
                <b>${fNKL(Math.floor(accountManualValue) - activeStockAccount.deposits)}</b>{" "}
                <span style={{ fontSize: "10px", fontWeight: 500 }}>({(((accountManualValue - activeStockAccount.deposits) / accountManualValue) * 100).toFixed(1)}%)</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockHeaderStats;
