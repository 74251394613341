import { Fragment, memo, useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import axios from "axios";
import Chart from "react-apexcharts";
import StockTradesTable from "./StockTradesTable";

const StockSymbolChart = memo(({ symbol, avgPrice, last, token, remoteConfig, trades, transactions, privacyMode, refresh }) => {
  const [gainsData, setGainsData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [range, setRange] = useState("12mo");
  const [periodPerformancePercent, setPeriodPerformancePercent] = useState();
  const [periodPerformanceAmount, setPeriodPerformanceAmount] = useState();
  const [showAverage, setShowAverage] = useState(false);

  const dateFormater = (data) => {
    if (range === "3mo" || range === "6mo" || range === "ytd") {
      return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-uk", { day: "numeric", month: "short" }));
    } else if (range === "12mo" || range === "2y" || range === "5y" || range === "max") {
      return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-uk", { day: "numeric", month: "short", year: "2-digit" }));
    } else {
      return data.map((item) => new Date(item != null && item * 1000).toLocaleString("en-uk", { month: "narrow" }));
    }
  };

  const dataFN = () => {
    return [
      {
        name: "Gain",
        data: gainsData.map((item) => Number(item != null && item.toFixed(2))),
      },
    ];
  };

  let options = {
    annotations: {
      yaxis: [
        {
          y: showAverage ? avgPrice : -1000,
          borderColor: "black",
          borderWidth: 2,
          label: {
            borderColor: "grey",
            style: {
              color: "white",
              background: "grey",
            },
            text: `Av.: ${avgPrice.toFixed(2)}`,
          },
        },
      ],
    },
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: periodPerformanceAmount > 0 ? ["green"] : ["red"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0,
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.3,
      },
      padding: {
        top: -20,
        bottom: -10,
      },
    },
    xaxis: {
      categories: dateFormater(datesData),
      tickAmount: 16,
    },
  };

  const getChart = async (interval, cancelToken) => {
    try {
      setGainsData([]);
      const theChart = await axios.get(`${remoteConfig.stockURL}/chart/${symbol}?appToken=${token}&range=${range || "12mo"}&interval=${interval || "1mo"}`, { cancelToken });
      setGainsData(theChart.data.chart.result[0].indicators.quote[0].close);
      setDatesData(theChart.data.chart.result[0].timestamp);
      setPeriodPerformancePercent(((last / theChart.data.chart.result[0].indicators.quote[0].close[0]) * 100 - 100).toFixed(2));
      setPeriodPerformanceAmount((last - theChart.data.chart.result[0].indicators.quote[0].close[0]).toFixed(2));
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        // handle error
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (range === "3mo" || range === "6mo" || range === "ytd" || range === "12mo") {
      getChart("1d", source.token);
    } else if (range === "2y" || range === "5y" || range === "max") {
      getChart("5d", source.token);
    } else {
      getChart(null, source.token);
    }

    return () => {
      source.cancel("Component unmounted");
    };
  }, [range, refresh]);

  return gainsData.length > 0 ? (
    <Fragment>
      <div style={{ textAlign: "center", fontSize: "12px", marginBottom: "6px" }}>
        <span className="stock-chart-period-performance" style={periodPerformanceAmount > 0 ? { color: "green" } : { color: "red" }}>
          ${periodPerformanceAmount} | <b>{periodPerformancePercent}%</b>
        </span>
        <span style={range === "ytd" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("ytd")}>
          YTD{" | "}
        </span>
        <span style={range === "3mo" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("3mo")}>
          3M{" | "}
        </span>
        <span style={range === "6mo" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("6mo")}>
          6M{" | "}
        </span>
        <span style={range === "12mo" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("12mo")}>
          1Y{" | "}
        </span>
        <span style={range === "2y" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("2y")}>
          2Y{" | "}
        </span>
        <span style={range === "5y" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("5y")}>
          5Y{" | "}
        </span>
        <span style={range === "max" ? { fontWeight: 600, textDecoration: "underline" } : null} onClick={() => setRange("max")}>
          MAX
        </span>
        <span
          className="stock-chart-period-average"
          style={showAverage ? { backgroundColor: "#2c3e50" } : { backgroundColor: "rgb(236, 236, 236)", color: "grey" }}
          onClick={() => setShowAverage(!showAverage)}
        >
          Average
        </span>
      </div>

      <div id="chart" className="stock-symbol-chart-wrap">
        <Chart options={options} series={dataFN()} type="area" height={250} />
      </div>

      <div>
        <StockTradesTable data={trades} filteredStock={symbol.toUpperCase()} count={1000} title={"Latest Trades"} privacyMode={privacyMode} />
      </div>

      <br />
      <br />
    </Fragment>
  ) : (
    <div style={{ textAlign: "center" }}>
      <IonSpinner />
    </div>
  );
});

export default StockSymbolChart;
