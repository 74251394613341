import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Capacitor } from "@capacitor/core";

import { IonContent, IonPage, IonIcon, IonToolbar } from "@ionic/react";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { editChildData } from "../functions/funcFirebase";

import "./Account.css";
// import { logoutUser } from "../firebaseFunctions";
import { person } from "ionicons/icons";
import AddAccount from "../components/appsettings/AddAccount";

const Account: React.FC = () => {
  const name = useSelector((state: any) => state.user.displayName);
  const email = useSelector((state: any) => state.user.email);
  const lastLogin = useSelector((state: any) => state.user.metadata && state.user.metadata.lastSignInTime);
  const userID = useSelector((state: any) => state.user.uid);

  const nullEntry: any[] = [];
  // const [notifications, setnotifications] = useState(nullEntry);

  const showToast = async (msg: string) => {
    await Toast.show({
      text: msg,
      duration: "long",
    });
  };

  const addToken = async (token: any) => {
    editChildData(userID, `/pushToken/token`, token);
  };

  useEffect(() => {
    // console.log("Capacitor.getPlatform(): ", Capacitor.getPlatform());
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === "denied") {
              showToast("Push Notification permission denied");
            } else {
              showToast("Push Notification permission granted");
              register();
            }
          });
        } else {
          register();
        }
      });
    }
  }, []);

  const register = () => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      // console.log("Plugin availability: ", Capacitor.isPluginAvailable("PushNotifications"));
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token: Token) => {
        // showToast("Push registration success");
        addToken(token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        alert("Error on registration: " + JSON.stringify(error));
        // setTheTokenError(JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
        // setnotifications((notifications) => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: "foreground" }]);
      });

      // Method called when tapping on a notification
      PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
        // setnotifications((notifications) => [
        //   ...notifications,
        //   { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: "action" },
        // ]);
      });
    } else {
      showToast("Plugin Not Available!!!");
      // console.log("Plugin availability: ", Capacitor.isPluginAvailable("PushNotifications"));
    }
  };

  return (
    <IonPage>
      {/* <IonToolbar mode="ios"></IonToolbar> */}
      <IonContent>
        <div className="the-top-container">
          <div className="account-header">
            <div className="account-title">
              <IonIcon icon={person} size="10px" style={{ marginBottom: "-4px", marginRight: "4px" }} />
              ACCOUNT
            </div>
            <div className="account-subtitle"> View or Change Your Profile</div>
          </div>
          {userID ? (
            <Fragment>
              <div className="account-info">
                <div className="accunt-info-wrapper">
                  <div className="account-info-title">PROFILE INFORMATION</div>
                  <div className="account-info-item">
                    <b>Name:</b> {name}
                  </div>
                  <div className="account-info-item">
                    <b>eMail:</b> {email}
                  </div>
                  <div className="account-info-item">
                    <b>Last Login:</b> {lastLogin}
                  </div>
                  {/* <div className="account-info-item">
                  <b>FB User ID:</b> {userID}
                </div> */}
                </div>
              </div>
              <AddAccount userID={userID} />
            </Fragment>
          ) : (
            "Please Login"
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Account;
