import React from "react";

const CryptoPanels: React.FC<any> = ({ secondaryPanel, setSecondaryPanel }): JSX.Element => {
  return (
    <div className="crypto-secondary-panel-wrapper">
      <>
        <div className={secondaryPanel === "mycrypto" ? "crypto-news-header" : "crypto-news-header-inactive"} onClick={() => setSecondaryPanel("mycrypto")}>
          My Crypto
        </div>
        <div className={secondaryPanel === "watchlist" ? "crypto-news-header" : "crypto-news-header-inactive"} onClick={() => setSecondaryPanel("watchlist")}>
          Watchlist
        </div>
        <div className={secondaryPanel === "topnews" ? "crypto-news-header" : "crypto-news-header-inactive"} onClick={() => setSecondaryPanel("topnews")}>
          Top News
        </div>
        <div className={secondaryPanel === "topcrypto" ? "crypto-news-header" : "crypto-news-header-inactive"} onClick={() => setSecondaryPanel("topcrypto")}>
          Top Coins
        </div>
      </>
    </div>
  );
};

export default CryptoPanels;
