import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IonCol, IonContent, useIonAlert, IonPage, IonRow } from "@ionic/react";
import { syncOutline, fingerPrintOutline, addOutline, businessOutline, trashOutline } from "ionicons/icons";

import { pushRecord, getChildData, editChildData } from "../functions/funcFirebase";
import { getAssetsStats } from "../functions/assetsFunctions";
import AssetsStats from "../components/assets/AssetsStats";
import AssetsAddEdit from "../components/assets/AssetsAddEdit";
import AssetsNotAddedYet from "../components/assets/AssetsNotAddedYet";
import AssetsItems from "../components/assets/AssetsItems";
import AssetsHeader from "../components/assets/AssetsHeader";

const Assets = () => {
  const userID = useSelector((state) => state.user.uid);
  const userData = useSelector((state) => state.data);
  const [activeID, setActiveID] = useState("");
  const [assetsData, setAssetsData] = useState([]);
  const [assetsStats, setAssetsStats] = useState({ total: 0, realEstate: 0, cash: 0, other: 0 });
  const [privacyMode, setPrivacyMode] = useState(false);
  const [refreshStats, setRefreshStates] = useState(0);
  const [formType, setFormType] = useState("");
  const [form, setForm] = useState({});
  const [present] = useIonAlert();

  const getAssets = async () => {
    const resData = await getChildData(userID, "assets");
    setAssetsData(resData && resData.data && Object.entries(resData.data));
  };

  const prepareAssetsStats = () => {
    setAssetsStats(getAssetsStats(userData.assets));
  };

  const addAsset = () => {
    const path = "assets";
    pushRecord(userID, path, form);
    setForm({});
    setFormType("");
    getAssets();
  };

  const editItem = async (id) => {
    setForm({});
    setActiveID(id);
    setFormType("edit");
    const resData = await getChildData(userID, `/assets/${id}`);
    setForm(resData.data);
  };

  const editAsset = async () => {
    setFormType("edit");
    editChildData(userID, `assets/${activeID}`, form);
    setFormType("");
    setForm({});
    getAssets();
  };

  const deleteAsset = async (id) => {
    editChildData(userID, `assets/${id}`, null);
    getAssets();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handelAddAsset = () => {
    setForm({});
    setFormType("add");
  };

  useEffect(() => {
    getAssets();
    prepareAssetsStats();
  }, [refreshStats]);

  return (
    <IonPage>
      {/* <IonToolbar mode="ios" id="sto"></IonToolbar> */}

      <IonContent>
        <div className="the-top-container">
          <AssetsHeader
            privacyMode={privacyMode}
            setPrivacyMode={setPrivacyMode}
            handelAddAsset={handelAddAsset}
            fingerPrintOutline={fingerPrintOutline}
            businessOutline={businessOutline}
            addOutline={addOutline}
            syncOutline={syncOutline}
            setRefreshStates={setRefreshStates}
          />

          <AssetsStats assetsStats={assetsStats} />

          <IonRow>
            <IonCol size="12">
              <div>
                {assetsData && assetsData.length > 0 ? (
                  assetsData.map((item) => {
                    let theKey = item[0];
                    let theData = item[1];
                    return (
                      <AssetsItems
                        key={theKey}
                        theKey={theKey}
                        theData={theData}
                        editItem={editItem}
                        deleteAsset={deleteAsset}
                        trashOutline={trashOutline}
                        present={present}
                        privacyMode={privacyMode}
                      />
                    );
                  })
                ) : (
                  <AssetsNotAddedYet setFormType={setFormType} />
                )}

                <AssetsAddEdit formType={formType} form={form} handleChange={handleChange} setForm={setForm} addAsset={addAsset} editAsset={editAsset} setFormType={setFormType} />
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Assets;
