import { Fragment } from "react";
import Chart from "react-apexcharts";

import { fNK } from "../../functions/baseFunctions";

const Dca = ({ stockDCA }) => {
  return (
    stockDCA &&
    Object.entries(stockDCA.stocks).map((data) => {
      let index = data[0];
      let item = data[1];

      return (
        <Fragment key={index}>
          <div style={{ textAlign: "center" }}>
            <div style={{ marginTop: "5px", marginBottom: "0px" }}>
              <span style={{ fontSize: "13px", letterSpacing: -0.8, fontWeight: 400 }}>
                ${fNK(item.bought)} / <b>${fNK(item.budget)}</b>
              </span>
            </div>

            <div key={index} style={{ float: "left", margin: "0px", padding: "0px" }}>
              <Chart
                options={{
                  grid: {
                    padding: {
                      top: -10,
                      bottom: -10,
                    },
                  },
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        margin: 10,
                        size: "55%",
                      },
                      dataLabels: {
                        name: {
                          offsetY: -5,
                          show: true,
                          color: "#888",
                          fontSize: "14px",
                          fontWeight: 800,
                        },
                        value: {
                          offsetY: -2,
                          color: "#111",
                          fontSize: "16px",
                          show: true,
                        },
                      },
                    },
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [item.symbol.toUpperCase()],
                  colors: [(item.bought / item.budget) * 100 > 75 ? "hsl(93, 67%, 38%)" : "hsl(48, 95%, 48%)"],
                }}
                series={[((Number(item.bought) / Number(item.budget)) * 100).toFixed(1)]}
                type="radialBar"
                width="90"
                height="130"
              />
              <p />
            </div>
          </div>
        </Fragment>
      );
    })
  );
};

export default Dca;
