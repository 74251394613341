import { IonAlert } from "@ionic/react";
import { pushRecord } from "../../functions/funcFirebase";

const StockAddNote = ({ userID, showAddNote, setShowAddNote, symbolToAdd }) => {
  const itemName = "stockNotes";

  const handler = (e) => pushRecord(userID, itemName, e);

  return (
    <IonAlert
      isOpen={showAddNote}
      onDidDismiss={() => setShowAddNote(false)}
      header="Add a Note"
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
        },

        {
          text: "Add",
          role: "confirm",
          handler: (e) => {
            handler(e);
          },
        },
      ]}
      inputs={[
        {
          name: "symbol",
          placeholder: "symbol",
          value: symbolToAdd,
        },

        {
          name: "notes",
          type: "textarea",
          placeholder: "your notes..",
          value: "",
        },
      ]}
    />
  );
};

export default StockAddNote;
