import { IonButton, IonIcon } from "@ionic/react";
import { walletOutline, eyeOutline, nutritionOutline, searchOutline, analyticsOutline, readerOutline, closeCircleOutline, gitCompareOutline } from "ionicons/icons";
import { Fragment } from "react";

const StockSearchBlock = ({ setStockBlsBlock, stockBlsBlock, filteredStock, setFilteredStock, setStockNAV, stockNAV, setStockSettings, stockSettings }) => {
  const handleOption = (option) => {
    setStockSettings({ ...stockSettings, [option]: !stockSettings[option] });
  };

  return (
    <Fragment>
      <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
        {/* TRANSACTIONS ICON */}
        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton routerLink="/stocks/transactions" fill="clear">
              <IonIcon icon={walletOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div style={{ margin: 0, padding: 0, marginTop: "-9px", fontSize: "9px", textTransform: "uppercase" }}>Trans.</div>
        </div>

        {/* STOCK WATCH LIST ICON */}
        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton routerLink="/stocks/watchlist" fill="clear">
              <IonIcon icon={eyeOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div className="stock-top-icons">Watch</div>
        </div>

        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton onClick={() => setStockBlsBlock(!stockBlsBlock)} fill="clear">
              <IonIcon icon={nutritionOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div className="stock-top-icons">Stats</div>
        </div>

        {/* STOCK NAV ICON */}
        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton onClick={() => handleOption("searchInput")} fill="clear">
              <IonIcon icon={searchOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div className="stock-top-icons">Search</div>
        </div>

        {/* STOCK NAV ICON */}
        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton onClick={() => setStockNAV(!stockNAV)} fill="clear">
              <IonIcon icon={analyticsOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div className="stock-top-icons">NAV</div>
        </div>

        {/* STOCK BALANCE */}
        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton routerLink="/stocks/dca" fill="clear">
              <IonIcon icon={gitCompareOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div className="stock-top-icons">Balance</div>
        </div>

        {/* TRADES ICON */}
        <div>
          <div style={{ margin: 0, padding: 0 }}>
            <IonButton routerLink="/stocks/trades" fill="clear">
              <IonIcon icon={readerOutline} className="stock-search-icons" />
            </IonButton>
          </div>
          <div className="stock-top-icons">Trades</div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
        {stockSettings.searchInput && (
          <input type="text" placeholder="search .." value={filteredStock} className="stock-search-input" onChange={(e) => setFilteredStock(e.target.value.toUpperCase())} />
        )}
        {filteredStock !== "" && <IonIcon icon={closeCircleOutline} style={{ size: "8px", marginBottom: "-20px" }} onClick={() => setFilteredStock("")} />}
      </div>
    </Fragment>
  );
};

export default StockSearchBlock;
