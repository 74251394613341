const StocksTableHead = ({ stockSortedItem, sortHandler, liveStockData, stockSettings }) => {
  return (
    <thead>
      <tr className="stock-details-header">
        <th className={stockSortedItem === "symbol" ? "stock-details-item-symbol-header-active" : "stock-details-item-symbol-header"} onClick={() => sortHandler("symbol")}>
          SYM <span style={{ fontSize: "10px" }}>({liveStockData.length})</span>
        </th>
        {stockSettings.last && (
          <th
            className={stockSortedItem === "last" ? "stock-details-header-active" : "stock-details-header"}
            onClick={() => sortHandler("last")}
            style={stockSettings.mode === "wide" ? { minWidth: "60px" } : null}
          >
            Last
          </th>
        )}

        {stockSettings.avgPrice && (
          <th
            className={stockSortedItem === "avgPrice" ? "stock-details-header-active" : "stock-details-header"}
            onClick={() => sortHandler("avgPrice")}
            style={stockSettings.mode === "wide" ? { minWidth: "60px" } : null}
          >
            Avg.P
          </th>
        )}

        {stockSettings.change && (
          <th className={stockSortedItem === "change" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("change")}>
            Chn.$
          </th>
        )}

        {stockSettings.changePercent && (
          <th className={stockSortedItem === "changePercent" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("changePercent")}>
            Chn.%
          </th>
        )}
        {stockSettings.position && (
          <th className={stockSortedItem === "position" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("position")}>
            Position
          </th>
        )}

        {stockSettings.averageAnalystRating && (
          <th className={stockSortedItem === "averageAnalystRating" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("averageAnalystRating")}>
            Rate
          </th>
        )}

        {stockSettings.percentOfNAV && (
          <th className={stockSortedItem === "percentOfNAV" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("percentOfNAV")}>
            NAV%
          </th>
        )}

        {stockSettings.marketValue && (
          <th className={stockSortedItem === "marketValue" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("marketValue")}>
            M.Value
          </th>
        )}

        {stockSettings.dividendPercent && (
          <th className={stockSortedItem === "dividendPercent" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("dividendPercent")}>
            Div%
          </th>
        )}

        {stockSettings.dividendTotal && (
          <th className={stockSortedItem === "dividendTotal" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("dividendTotal")}>
            Div/Y
          </th>
        )}

        {stockSettings.dividendDate && (
          <th className={stockSortedItem === "dividendDate" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("dividendDate")}>
            Div.D
          </th>
        )}

        {stockSettings.forwardPE && (
          <th className={stockSortedItem === "forwardPE" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("forwardPE")}>
            F.PE
          </th>
        )}

        {stockSettings.unrealized_percent && (
          <th className={stockSortedItem === "unrealized_percent" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("unrealized_percent")}>
            Unrlz.%
          </th>
        )}

        {stockSettings.unrealized_pnl && (
          <th className={stockSortedItem === "unrealized_pnl" ? "stock-details-header-active" : "stock-details-header"} onClick={() => sortHandler("unrealized_pnl")}>
            Unrlz
          </th>
        )}

        {stockSettings.pnl && (
          <th className={stockSortedItem === "pnl" ? "stock-details-item-pnl-header-active" : "stock-details-item-pnl-header"} onClick={() => sortHandler("pnl")}>
            P&L
          </th>
        )}
      </tr>
    </thead>
  );
};

export default StocksTableHead;
