import { IonAlert } from "@ionic/react";
import { pushRecord } from "../../functions/funcFirebase";

const StockAddWatchList = ({ userID, showAddWatchList, setShowAddWatchList, symbolToAdd }) => {
  const itemName = "stockWatchList";

  const handler = (e) => pushRecord(userID, itemName, e);

  return (
    <IonAlert
      isOpen={showAddWatchList}
      onDidDismiss={() => setShowAddWatchList(false)}
      header="Add to Your Watch List"
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
        },

        {
          text: "Add",
          role: "confirm",
          handler: (e) => {
            handler(e);
          },
        },
      ]}
      inputs={[
        {
          name: "symbol",
          placeholder: "symbol",
          value: symbolToAdd,
        },
        {
          name: "watchPrice",
          placeholder: "e.g. 14.5",
          value: "",
        },

        {
          name: "notes",
          type: "textarea",
          placeholder: "your notes..",
          value: "",
        },
      ]}
    />
  );
};

export default StockAddWatchList;
