import { Fragment } from "react";
import { fN, dForm } from "../../functions/baseFunctions";

const StockTradesTable = ({ data, filteredStock, count, title, privacyMode }) => {
  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <div className="stock-trades-title">
          {title ? (
            <span>
              {title} - {data && data.length && fN(data.length)}
            </span>
          ) : (
            <span>Trades - {data && fN(data.length)}</span>
          )}
        </div>

        <table className="stock-main-table">
          <thead>
            <tr className="stock-trades-header">
              <th>Stock</th>
              <th>Trade</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th>Commision</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data
                .sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1))
                .filter((val) => {
                  if (filteredStock === "") {
                    return val;
                  } else if (val.symbol.includes(filteredStock)) {
                    return val;
                  }
                })
                .slice(0, count)
                .map((item, index) => (
                  <tr className="stock-trades-item" key={index}>
                    <td style={{ textAlign: "left" }}>
                      <b>{item.symbol && item.symbol.slice(0, 6)}</b>
                    </td>
                    <td>{item.buySell}</td>
                    <td style={{ textAlign: "left" }}>${privacyMode ? "***" : item.tradePrice}</td>
                    <td>{privacyMode ? "***" : <b>{item.quantity}</b>}</td>
                    <td style={{ textAlign: "left" }}>${privacyMode ? "***" : fN(Math.floor(item.cost))}</td>
                    <td>{item.ibCommission}</td>
                    <td style={{ textAlign: "left" }}>{dForm(item.dateTime)}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default StockTradesTable;
