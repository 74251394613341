import { Fragment, useEffect, useState } from "react";
import { IonIcon, useIonAlert, IonSpinner } from "@ionic/react";
import { pushRecord, getChildData, editChildData } from "../../functions/funcFirebase";
import { getStockInfo } from "../../functions/stockFunctions";
import { trunc, fN } from "../../functions/baseFunctions";
import { trashOutline, createOutline, addOutline } from "ionicons/icons";

const StockManualTable = ({ userID, activeStockAccount, remoteConfig, stockSettings, stockSortedItem, privacyMode, setManualStockAccountInfo }) => {
  const [stockInfoStats, setStockInfoStats] = useState({ total: 0, deposits: 0, costBasis: 0, profits: 0, numberOfStocks: 0, dailyPNL: 0, stocksROI: 0 });
  const [rawPositions, setRawPositions] = useState([]);
  const [form, setForm] = useState({});
  const [formType, setFormType] = useState("");
  const [activeID, setActiveID] = useState("");
  const [liveStockData, setLiveStockData] = useState([]);
  // const [sortOperator, setSortOperator] = useState(true);
  const [present] = useIonAlert();

  const getAccountData = async () => {
    const resData = await getChildData(userID, `account-data${activeStockAccount.id}`);
    setRawPositions(resData && resData.data && resData.data.positions && Object.entries(resData.data.positions));
  };

  const getStocks = async (rawPositions) => {
    const stockInfo = await getStockInfo(userID, remoteConfig, rawPositions);
    setLiveStockData(stockInfo.stocks);
    setManualStockAccountInfo(stockInfo.stocks);
    setStockInfoStats(stockInfo.stats);
  };

  const addStock = () => {
    const path = `account-data${activeStockAccount.id}/positions`;
    pushRecord(userID, path, form);
    setForm({});
    setFormType("");
    getAccountData();
  };

  const editItem = async (id) => {
    setForm({});
    setActiveID(id);
    setFormType("edit");
    const resData = await getChildData(userID, `/account-data${activeStockAccount.id}/positions/${id}`);
    setForm(resData.data);
  };

  const editStock = async (id) => {
    setFormType("edit");
    editChildData(userID, `/account-data${activeStockAccount.id}/positions/${id}`, form);
    setFormType("");
    setForm({});
    setFormType("");
    getAccountData();
  };

  const deleteStock = async (id) => {
    editChildData(userID, `/account-data${activeStockAccount.id}/positions/${id}`, null);
    getAccountData();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const cancelForm = () => {
    setForm({});
    setFormType("");
  };

  useEffect(() => {
    activeStockAccount.type !== "IBKR" && getAccountData();
  }, [activeStockAccount]);

  useEffect(() => {
    if (rawPositions && rawPositions.length > 0) {
      getStocks(rawPositions);
    }
  }, [rawPositions]);

  return (
    <Fragment>
      <div>
        {(formType === "add" || formType === "edit") && (
          <div className="stock-account-form-edit">
            <table className="stock-account-table">
              <tbody>
                <tr>
                  <td style={{ width: "40%" }}>Symbol:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="symbol" value={form["symbol"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Position:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="position" value={form["position"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Cost Basis:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="costBasis" value={form["costBasis"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Description:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="desc" value={form["desc"] || ""} onChange={handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>

            <p />

            {formType === "add" && (
              <div className="bottons-divider">
                <div onClick={() => addStock()} className="general-button-small">
                  Add
                </div>
                <div onClick={() => cancelForm()} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
            {formType === "edit" && (
              <div className="bottons-divider">
                <div onClick={() => editStock(activeID)} className="general-button-small">
                  Save
                </div>
                <div onClick={() => cancelForm()} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {rawPositions && rawPositions.length > 0 ? (
        <Fragment>
          {formType === "" && (
            <div style={{ justifyContent: "center", textAlign: "center", display: "grid" }}>
              <div className="general-button-small" style={{ width: "100%" }} onClick={() => setFormType("add")}>
                <IonIcon icon={addOutline} style={{ paddingRight: "4px", marginBottom: "-5px", color: "yellow", fontSize: "20px" }} />
                Add Position
              </div>
            </div>
          )}

          <div style={{ margin: "4px" }}>
            <table className="stock-main-table">
              <thead>
                <tr className="stock-details-header">
                  <th className={"stock-details-header-left"}>
                    Stock <span style={{ fontSize: "10px" }}>({liveStockData.length})</span>
                  </th>
                  {stockSettings.last && <th className={stockSortedItem === "last" ? "stock-details-header-active-left" : "stock-details-header-left"}>Last</th>}

                  {stockSettings.change && <th className={stockSortedItem === "change" ? "stock-details-header-active" : "stock-details-header"}>Change</th>}

                  {stockSettings.changePercent && <th className={stockSortedItem === "changePercent" ? "stock-details-header-active" : "stock-details-header"}>%</th>}
                  {stockSettings.position && <th className={stockSortedItem === "position" ? "stock-details-header-active" : "stock-details-header"}>Position</th>}
                  {stockSettings.pnl && (
                    <th style={{ textAlign: "right" }} className={stockSortedItem === "pnl" ? "stock-details-header-active" : "stock-details-header"}>
                      PnL
                    </th>
                  )}
                  {stockSettings.marketValue && (
                    <th className={stockSortedItem === "marketValue" ? "stock-details-header-active" : "stock-details-header"} style={{ textAlign: "right" }}>
                      M.Value
                    </th>
                  )}
                  {stockSettings.percentOfNAV && (
                    <th className={stockSortedItem === "percentOfNAV" ? "stock-details-header-active" : "stock-details-header"} style={{ textAlign: "right" }}>
                      NAV%
                    </th>
                  )}
                  <th style={{ textAlign: "right" }}>_</th>
                </tr>
              </thead>

              <tbody>
                {liveStockData && liveStockData.length > 0 ? (
                  liveStockData.map((item, index) => (
                    <Fragment key={index}>
                      <tr className="stock-details-item">
                        <td style={{ textAlign: "left" }}>
                          <span style={{ fontWeight: 500 }}>{item.symbol && item.symbol.slice(0, 6).toUpperCase()}</span>
                          <div className="stocks-table-company">{item.desc && trunc(item.desc, 20)}</div>
                        </td>

                        {stockSettings.last && <td>{item.last.toFixed(2)}</td>}

                        {stockSettings.change && (
                          <td style={{ textAlign: "center" }}>
                            {item.change >= 0 ? (
                              <span style={{ color: "green" }}>{item.change && Number(item.change).toFixed(2)}</span>
                            ) : (
                              <span style={{ color: "red" }}>{item.change && Number(item.change).toFixed(2)}</span>
                            )}
                          </td>
                        )}

                        {stockSettings.changePercent && (
                          <td style={{ textAlign: "center" }}>
                            {item.change >= 0 ? (
                              <span style={{ color: "green" }}>{item.changePercent.toFixed(2)}%</span>
                            ) : (
                              <span style={{ color: "red" }}>{item.changePercent.toFixed(2)}%</span>
                            )}
                          </td>
                        )}

                        {stockSettings.position && <td style={{ textAlign: "center" }}>{privacyMode ? "***" : item.position}</td>}
                        {stockSettings.pnl && (
                          <td style={{ textAlign: "right" }}>
                            {privacyMode ? (
                              "***"
                            ) : item.change >= 0 ? (
                              <span style={{ color: "green" }}>{fN(Math.floor(item.change * item.position))}</span>
                            ) : (
                              <span style={{ color: "red" }}>{fN(Math.floor(item.change * item.position))}</span>
                            )}
                          </td>
                        )}

                        {stockSettings.marketValue && <td style={{ textAlign: "right" }}>{privacyMode ? "***" : <span>{fN(Math.floor(item.last * item.position))}</span>}</td>}
                        {stockSettings.percentOfNAV && (
                          <td style={{ textAlign: "right" }}>{privacyMode ? "***" : <span>{(((item.last * item.position) / stockInfoStats.total) * 100).toFixed(2)}%</span>}</td>
                        )}

                        <td style={{ textAlign: "right" }}>
                          {" "}
                          <IonIcon
                            icon={createOutline}
                            onClick={() => editItem(item.fbID)}
                            style={{ paddingRight: "4px", paddingLeft: "16px", color: "darkblue", fontSize: "18px" }}
                          />
                          <IonIcon
                            icon={trashOutline}
                            onClick={() =>
                              present({
                                cssClass: "my-css",
                                header: "Delete",
                                message: "Are you sure you want to delete this item?",
                                buttons: ["Cancel", { text: "Delete", handler: (d) => deleteStock(item.fbID) }],
                              })
                            }
                            style={{ paddingLeft: "4px", color: "darkred", fontSize: "18px" }}
                          />
                        </td>
                      </tr>
                    </Fragment>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center", marginTop: "30px" }}>
                      <IonSpinner />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ justifyContent: "center", textAlign: "center", display: "grid" }}>
            <div>There are no positions yet. </div>
            <br />
            {formType === "" && (
              <div className="general-button-small" onClick={() => setFormType("add")}>
                <IonIcon icon={addOutline} style={{ paddingRight: "4px", marginBottom: "-5px", color: "yellow", fontSize: "20px" }} />
                Add Position
              </div>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default StockManualTable;
