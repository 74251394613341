import axios from "axios";
import { useEffect, useState } from "react";
import { IonIcon, IonSpinner } from "@ionic/react";
import { chevronForwardCircle } from "ionicons/icons";

const StockNews = ({ stock, remoteConfig, userID }) => {
  const [news, setNews] = useState({});
  const [newsIndex, setNewsIndex] = useState(0);

  const getNews = async () => {
    const getNews = await axios(`${remoteConfig.stockURL}/news?sym=${stock}&appToken=${userID}`);
    setNews(getNews.data.news);
  };

  useEffect(() => {
    getNews();
  }, []);

  return news && news.feed && news.feed.length > 0 ? (
    news.feed.map((item, index) => (
      <div key={index} className="stock-news-title" onClick={() => setNewsIndex(index)}>
        <IonIcon icon={chevronForwardCircle} style={{ marginRight: "4px", fontSize: "18px" }} />
        {item.title}

        {newsIndex === index && (
          <div>
            <div className="stock-news-summary">
              {item.summary}
              <br />
              <br />
              <span style={{ fontSize: "11px" }}>
                <i>{item.url}</i>
              </span>
            </div>
            <br />
            <img src={item.banner_image} alt={item.source} />
          </div>
        )}
      </div>
    ))
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IonSpinner />
    </div>
  );
};

export default StockNews;
