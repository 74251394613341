import { IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonButton } from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";

const FireAddNewItem = ({ addItemActive, activeFireKey, fireKey, handleChange, form, addItem, setAddItemActive }) => {
  return (
    addItemActive &&
    activeFireKey === fireKey && (
      <>
        <IonItem>
          <IonLabel name="item">Item</IonLabel>
          <IonInput
            style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
            name="item"
            value={form["item"] || ""}
            placeholder="Enter Input"
            onIonChange={handleChange}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel name="amount">Amount</IonLabel>
          <IonInput
            style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
            name="amount"
            value={form["amount"] || ""}
            type="number"
            placeholder="Enter Input"
            onIonChange={handleChange}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel name="duration">Duration (years)</IonLabel>
          <IonInput
            style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
            name="duration"
            value={form["duration"] || ""}
            type="number"
            placeholder="Enter Input"
            onIonChange={handleChange}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel name="desc">Desc.</IonLabel>
          <IonInput
            style={{ fontSize: "12px", color: "green", fontWeight: 600 }}
            name="desc"
            value={form["desc"] || ""}
            placeholder="Enter Input"
            onIonChange={handleChange}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Type</IonLabel>
          <IonSelect name="type" value={form["type"] || ""} okText="Okay" cancelText="Dismiss" onIonChange={handleChange}>
            <IonSelectOption value="spending">Spending</IonSelectOption>
            <IonSelectOption value="income">Income</IonSelectOption>
          </IonSelect>
        </IonItem>
        <div style={{ textAlign: "center" }}>
          <IonButton size="small" onClick={() => addItem(fireKey, "items")} className="ion-margin-top">
            <IonIcon icon={addOutline} /> Add
          </IonButton>
          <IonButton size="small" onClick={() => setAddItemActive(false)} className="ion-margin-top">
            <IonIcon icon={closeOutline} /> Close
          </IonButton>
        </div>
      </>
    )
  );
};

export default FireAddNewItem;
