export const setUserState = (payload: any) => {
  return { type: "SET_USER_STATE", payload };
};

export const setDataState = (payload: any) => {
  return { type: "SET_DATA", payload };
};

export const setRemoteConfigState = (payload: any) => {
  return { type: "SET_REMOTE_CONFIG", payload };
};

export const setCryptoDataState = (payload: any) => {
  return { type: "SET_CRYPTO_DATA", payload };
};

export const setStockDataState = (payload: any) => {
  return { type: "SET_STOCK_DATA", payload };
};

export const setHomeCryptoDataState = (payload: any) => {
  return { type: "SET_HOME_CRYPTO_DATA", payload };
};

export const setHomeStockDataState = (payload: any) => {
  return { type: "SET_HOME_STOCK_DATA", payload };
};
