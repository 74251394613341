import { IonAlert } from "@ionic/react";
import { pushRecord } from "../../functions/funcFirebase";

const FireAddNew = ({ userID, showAddFire, setShowAddFire, getItems }) => {
  const itemName = "fire";

  const handler = (e) => {
    pushRecord(userID, itemName, e);
    getItems();
  };

  return (
    <IonAlert
      isOpen={showAddFire}
      onDidDismiss={() => setShowAddFire(false)}
      header="Add A New FIRE Plan"
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
        },

        {
          text: "Add",
          role: "confirm",
          handler: (e) => {
            handler(e);
          },
        },
      ]}
      inputs={[
        {
          name: "name",
          placeholder: "new FIRE plan name",
          value: "",
        },
        {
          name: "desc",
          type: "textarea",
          placeholder: "description",
          value: "",
        },
        {
          name: "fireNumber",
          value: "0",
          type: "hidden",
        },
        {
          name: "rulePercent",
          value: 4,
          type: "hidden",
        },
      ]}
    />
  );
};

export default FireAddNew;
