import { IonCol, IonIcon, IonRow } from "@ionic/react";

const AssetsHeader = ({ privacyMode, setPrivacyMode, handelAddAsset, fingerPrintOutline, businessOutline, addOutline, syncOutline, setRefreshStates }) => {
  return (
    <div className="stocks-header">
      <IonRow>
        <IonCol size="12">
          <div className="top-title">
            <IonIcon icon={businessOutline} style={{ marginBottom: -3 }} />
            <span style={{ fontWeight: 500, letterSpacing: "0.5px", fontSize: "30px" }}>
              {" "}
              Assets<span style={{ fontSize: "14px", padding: "2px" }}>&</span>Income
            </span>

            <div style={{ marginTop: "1px", marginRight: "0px", float: "right" }}>
              {privacyMode && <span className="crypto-privacy-mode">PRIVACY ON</span>}
              <IonIcon icon={fingerPrintOutline} onClick={() => setPrivacyMode(!privacyMode)} style={{ paddingLeft: "12px" }} />
              <IonIcon icon={addOutline} style={{ paddingLeft: "12px" }} onClick={() => handelAddAsset()} />
              <IonIcon icon={syncOutline} style={{ paddingLeft: "6px", paddingRight: "0px" }} onClick={() => setRefreshStates(Math.random())} />
              <p />
            </div>
          </div>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default AssetsHeader;
