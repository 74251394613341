import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { pieChart, businessOutline, home, cog, rocketSharp, flame, fileTrayStacked } from "ionicons/icons";
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";

import Stocks from "./pages/Stocks";
import Account from "./pages/Account";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Crypto from "./pages/Crypto";
import FirePage from "./pages/Fire";
import Assets from "./pages/Assets";
import Buckets from "./pages/Buckets";
import { IonReactRouter } from "@ionic/react-router";

const Tabs: React.FC = () => {
  const userID = useSelector((state: any) => state.user.uid);

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact={true} path="/" render={() => (userID ? <Redirect to="/home" /> : <Redirect to="/login" />)} />
          <Route exact={true} path="/login" component={Login} />
          <Route exact={true} path="/home" component={userID ? Home : Login} />
          <Route path="/stocks" component={userID ? Stocks : Login} />
          <Route path="/crypto" component={userID ? Crypto : Login} />
          <Route path="/assets" component={userID ? Assets : Login} />
          <Route path="/buckets" component={userID ? Buckets : Login} />
          <Route path="/firepage" component={userID ? FirePage : Login} />
          <Route path="/account" component={userID ? Account : Login} />
        </IonRouterOutlet>

        {userID ? (
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={home} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>

            <IonTabButton tab="stocks" href="/stocks">
              <IonIcon icon={pieChart} />
              <IonLabel>Stocks</IonLabel>
            </IonTabButton>

            <IonTabButton tab="crypto" href="/crypto">
              <IonIcon icon={rocketSharp} />
              <IonLabel>Crypto</IonLabel>
            </IonTabButton>

            <IonTabButton tab="assets" href="/assets">
              <IonIcon icon={businessOutline} />
              <IonLabel>Assets</IonLabel>
            </IonTabButton>

            <IonTabButton tab="buckets" href="/buckets">
              <IonIcon icon={fileTrayStacked} />
              <IonLabel>Buckets</IonLabel>
            </IonTabButton>

            <IonTabButton tab="firepage" href="/firepage">
              <IonIcon icon={flame} />
              <IonLabel>Fire</IonLabel>
            </IonTabButton>

            <IonTabButton tab="settings" href="/account">
              <IonIcon icon={cog} />
              <IonLabel>Settings</IonLabel>
            </IonTabButton>
          </IonTabBar>
        ) : (
          <IonTabBar slot="bottom" />
        )}
      </IonTabs>
    </IonReactRouter>
  );
};

export default Tabs;
