import { IonAlert } from "@ionic/react";
import { pushRecord } from "../../functions/funcFirebase";

const FireCloneAPlan = ({ userID, showCloneFirePlan, setShowCloneFirePlan, theData, getItems }) => {
  const itemName = "fire";

  const handler = (e) => {
    theData.name = e.name;
    theData.desc = e.desc;
    pushRecord(userID, itemName, theData);
    getItems();
  };

  return (
    <IonAlert
      isOpen={showCloneFirePlan}
      onDidDismiss={() => setShowCloneFirePlan(false)}
      header="Clone this FIRE Plan"
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
        },

        {
          text: "Clone",
          role: "confirm",
          handler: (e) => {
            handler(e);
          },
        },
      ]}
      inputs={[
        {
          name: "name",
          placeholder: "new FIRE plan name",
          value: "",
        },
        {
          name: "desc",
          placeholder: "new description",
          value: "",
        },
      ]}
    />
  );
};

export default FireCloneAPlan;
