import { fNK } from "../../functions/baseFunctions";

const StockGainersLosersTable = ({
  data,
  privacyMode,
  count,
  operator,
  title,
  tableClass,
  wrapperClass,
  filteredStock,
  setFilteredStock,
  setStockPanelOpen,
  setActiveStock,
  setRefresh,
}) => {
  const handleClick = (symbol) => {
    setStockPanelOpen(false);
    setFilteredStock(symbol);
    setActiveStock(symbol);
    setStockPanelOpen(true);
    setRefresh(symbol);
  };
  return (
    <div className={wrapperClass}>
      <div className={tableClass}>{title}</div>
      <table className="stock-main-table">
        <thead>
          <tr className="stock-trades-header">
            <th>Stock</th>
            <th>Price</th>
            <th style={{ textAlign: "center" }}>Change</th>
            <th style={{ textAlign: "right" }}>PnL</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data
              .sort((a, b) => (operator === "greaterThan" ? (parseFloat(a.pnl) < parseFloat(b.pnl) ? 1 : -1) : parseFloat(a.pnl) > parseFloat(b.pnl) ? 1 : -1))
              .slice(0, count || 8)
              .map((item, index) => (
                <tr
                  className="stock-gainlose-item"
                  key={index}
                  onClick={() => handleClick(item.symbol)}
                  style={filteredStock && filteredStock === item.symbol ? { backgroundColor: "#f7f7bf" } : null}
                >
                  <td style={{ textAlign: "left" }}>
                    {item && item.symbol && item.symbol.slice(0, 5)} {item && item.earningsToday && <span className="stock-earnings-tag">E</span>}{" "}
                    {item && item.dividendsToday && <span className="stock-dividend-tag">D</span>}
                  </td>
                  <td>{Number(item && item.last).toFixed(2)}</td>
                  <td style={item && item.change >= 0 ? { textAlign: "center", color: "green" } : { textAlign: "center", color: "red" }}>
                    {Number(item && item.change).toFixed(2)}
                    <span style={{ color: "grey" }}> / </span>
                    <b>{Number(item && item.changePercent).toFixed(2)}%</b>
                  </td>
                  <td style={item && item.pnl >= 0 ? { textAlign: "right", color: "green" } : { textAlign: "right", color: "red" }}>
                    {privacyMode ? "**" : fNK(Math.floor(item && item.pnl))}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockGainersLosersTable;
